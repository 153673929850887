import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ManageUsers from './ManageUsers.tsx';
import UserManagementLocation from './UserManagementLocation.tsx';

const ManageUsersRouter = () => (
  <Routes>
    <Route path="/" element={<ManageUsers />} />
    <Route path="/location/:customerLocationId" element={<UserManagementLocation />} />
  </Routes>
);

export default ManageUsersRouter;
