import medTraxLogo from '../../assets/medtrax_logo.png';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { toast } from '../../func';
import Axios from 'axios';

const ForgotPassword = props => {


    const [errorMessage, setErrorMessage] = useState('');
    const [emailAddress, setEmailAddress] = useState('');

    const onChange = ({ target: { value } }) => {
        setErrorMessage('');
        setEmailAddress(value);
    }

    const submit = e => {
        e?.preventDefault();

        if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(emailAddress))
            return setErrorMessage('Invalid Email Address');

        Axios.post('/api/v1/auth/create/one/temporarypassword', {
            emailAddress
        })
            .then(result => {
                toast(result.data);
                setErrorMessage('');
                setEmailAddress('');
            })
            .catch(err => setErrorMessage(err?.response?.data))
    }

    return (
        <div>
            <div className="row">
                <img src={medTraxLogo} alt="MedTrax Logo" style={{ maxHeight: '80px', margin: '20px' }} />
            </div>
            <div className="row container">
                <div className="col s12 m10 offset-m1 l8 offset-l2 xl6 offset-xl3" style={{ marginTop: '15vh' }}>
                    <div className="card">
                        <div className="card-content" style={{ minHeight: '40vh' }}>
                            <div className="row" style={{ marginBottom: '60px' }}>
                                <h4 className="center blue-grey-text text-darken-1">Password Recovery</h4>
                            </div>
                            <div className="row">
                                <p className='center' style={{ fontSize: '1.1em' }}>Please input your email below. MedTrax will send a document to your email with instructions for logging in. If you do not see the email from us within a few minutes please check your spam/junk folder.</p>
                            </div>
                            <div className='row'>
                                <div className='input-field col s12'>
                                    <input id="emailAddress" type="text" value={emailAddress} onChange={onChange} onKeyDown={({ code }) => code.toLowerCase() === 'enter' ? submit() : null} />
                                    <label htmlFor='emailAddress'>Email Address</label>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col s10 offset-s1'>
                                    <a href="/" className='btn-small blue white-text waves-effect waves-light col s12' onClick={submit}>Submit</a>
                                </div>
                            </div>
                            <div className="row">
                                <p className="red-text center" style={{}}>{errorMessage}</p>
                            </div>
                        </div>
                        <Link className="right" style={{ paddingRight: '20px', paddingTop: '8px' }} to="/">Back to Login</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword;