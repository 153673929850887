import { useCallback, useEffect, useRef, useState } from "react";
import Axios from "axios";
import { logout, toast } from "../../func";
import { useParams } from 'react-router-dom';
import M from 'materialize-css';
import { getAuthData } from "../../common/api/helpers.ts";
import api from "../../common/api/index.ts";
import { useQueryClient } from "@tanstack/react-query";
import endpoints from "../../common/endpoints.ts";

const ReportAnIncident = props => {

    const { incidentId, type } = useParams();
    const [reportData, setReportData] = useState({
        epcisSgtinId: '',
        identifier: '',
        reportType: 'INITIAL',
        incidentNumber: '',
        initialNotificationDate: '',
        productFoundDate: '',
        classification: 'COUNTERFEIT',
        nameOfProduct: '',
        primaryIngredients: '',
        use: 'HUMAN USE',
        description: 'FINISHED PRESCRIPTION DRUG',
        strength: '',
        dosageForm: 'TABLET',
        quantity: '',
        ndc: '',
        serialNumber: '',
        lotNumber: '',
        expiration: '',
        eventNotes: '',
        terminationNotes: '',
        bpdr: false,
        medWatch3500: false,
        none: false,
        far: false,
        medWatch3500a: false,
        other: false,
        otherSpecify: '',
        companyName: '',
        addressLineOne: '',
        addressLineTwo: '',
        city: '',
        state: '',
        country: '',
        zip: '',
        companyCategory: 'DISPENSER (PHARMACY)',
        uniqueFacilityIdentifier: '',
        contactName: '',
        contactPhone: '',
        contactEmail: ''
    });
    const [serialNumber, setSerialNumber] = useState('');
    const [locationList, setLocationList] = useState([]);
    const [locationId, setLocationId] = useState(0);
    const [pdfBuffer, setPdfBuffer] = useState(null);
    const [pdfUrl, setPdfUrl] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [generating, setGenerating] = useState(false);
    const dropdownRefs = useRef({});
    const modalRef = useRef();
    const queryClient = useQueryClient();

    // These two functions are used to clear the materialize labels out of the way
    // and Init the dropdowns when the values are set. Timeouts had to be added because the 
    // network requests were finishing too fast and wouldn't work in the finally block, or with useEffects
    // monitoring a loading/searching variable
    const initDropdowns = () => {
        setTimeout(() => {
            Object.values(dropdownRefs.current).forEach(el => {
                M.FormSelect.init(el);
            })
        }, 400)
    }
    const clearLabels = () => {
        setTimeout(() => {
            M.updateTextFields();
        }, 400)
    }

    //

    const getLocationList = useCallback(() => {
        Axios.get('/api/v1/customerlocation/read/all/by/userid', {
            params: {
                ...getAuthData()
            }
        })
            .then(result => {
                if (!incidentId) {
                    setLocationId(result.data[0].id);
                }
                setLocationList(result.data);

            })
            .catch(logout)
            .finally(() => {
                initDropdowns();
            })
    }, [incidentId])

    const dataOnChange = ({ target: { id, value, type } }) => {
        setReportData(p => ({ ...p, [id]: type === 'checkbox' ? !p[id] : value }))
    }

    const handleToast = (err) => {
        if (err?.response.data) {
            toast(err.response.data);
        } else {
            console.log(err)
        }
    }

    const submitSerialNumber = (e) => {
        if (e.key === 'Enter') {
            Axios.get('/api/v1/med/read/one/by/serialnumber/incident', {
                params: {
                    ...getAuthData(),
                    serialNumber: serialNumber,
                    locationId: locationId
                }
            })
                .then(res => {

                    setReportData((prev) => ({
                        ...prev,
                        ...res.data,
                        serialNumber
                    })
                    );

                })
                .catch(err => {
                    handleToast(err);
                })
                .finally(() => {
                    clearLabels();
                    initDropdowns();
                })
        }
    }

    const submitIncident = async (e) => {
        e.preventDefault();
        setGenerating(true);
        try {

            const fileBuffer = await api.illegitReport.create.one(reportData);
            if (!fileBuffer) {
                return;
            }
            const blob = new Blob([fileBuffer], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            setPdfBuffer(fileBuffer);
            setPdfUrl(url);

        } catch (err) {
            const textDecoder = new TextDecoder('utf-8');
            toast(JSON.parse(textDecoder.decode(new Uint8Array(err?.response?.data))));
        } finally {
            setGenerating(false)
        }

    }

    const directLookup = useCallback(async () => {
        if (!incidentId) {
            return;
        }

        try {
            const reportData = await api.illegitReport.read.one.by.id({ id: incidentId });
            setReportData(prev => ({
                ...prev,
                ...reportData,
                reportType: type,
            }));

        } catch (err) {
            handleToast(err);
        } finally {
            initDropdowns();
            clearLabels();
        }
    }, [incidentId, type]);

    const saveDocument = async (e) => {
        e.preventDefault();

        setSubmitting(true);
        // FormData is used because need to send a binary file as well as the report data
        const reportForm = new FormData();
        reportForm.append('jsonData', JSON.stringify(reportData));
        reportForm.append('bufferData', new Blob([pdfBuffer], { type: 'application/pdf' }));

        try {
            // Auth data is placed in the headers for this request because it is sending both binary data and json info and
            // the token middleware will not be able to read it before the multer/formdata middleware can parse the form data
            await api.illegitReport.create.send(reportForm);
            if (!reportData.incidentNumber) {
                queryClient.invalidateQueries({ queryKey: [endpoints.illegitReport.read.count.by.userId()]})
            }
            toast({ type: 'GLOBAL_TOAST', payload: { msg: 'Form Sent', class: 'green white-text' } });
            closeModal();
        } catch (err) {
            handleToast(err);

        } finally {
            setSubmitting(false);
        }

    }

    const showModal = () => {
        const modal = M.Modal.getInstance(modalRef.current);
        if (modal) {
            modal.open();
        }
    }

    const closeModal = () => {
        const modal = M.Modal.getInstance(modalRef.current);
        if (modal) {
            modal.close();
        }
        modal.close();
        setPdfUrl(null);
    }

    useEffect(() => {

        getLocationList();
        directLookup();

        const modal = M.Modal.init(modalRef.current);
        if (modalRef.current) {
            return () => {
                if (modal) {
                    modal.destroy();
                }
            }
        }
    }, [getLocationList, directLookup]);

    useEffect(() => {
        if (pdfUrl) {
            showModal();
        }

        return () => {
            if (pdfUrl) {
                URL.revokeObjectURL(pdfUrl)
            }
        }
    }, [pdfUrl])

    useEffect(() => {
        if (reportData.none && !incidentId)
            setReportData(p => ({
                ...p,
                bdpr: false,
                far: false,
                medWatch3500: false,
                medWatch3500a: false,
                other: false,
                otherSpecify: ''
            }))
    }, [reportData.none, incidentId]);

    return (
        <div>
            <div>
                <div className="row">
                    <h6>Search</h6>
                    <div className="input-field col s4 m4 l4">
                        <input
                            id="serialNumberSearch"
                            type="text"
                            value={serialNumber || ''}
                            onChange={(e) => setSerialNumber(e.target.value)}
                            onKeyDown={submitSerialNumber}
                        />
                        <label htmlFor="serialNumberSearch">Serial #</label>
                    </div>
                    <div className="input-field col s4 m4 l4">
                        <select ref={(el) => dropdownRefs.current.locationId = el} id="locationId" value={locationId} onChange={(e) => setLocationId(e.target.value)}>
                            {locationList?.map(l => {
                                return <option key={l.id} value={l.id}>{l.name}</option>
                            })}
                        </select>
                        <label htmlFor="locationId">Location </label>
                    </div>
                </div>
                <div className="divider" />
                <br />
                <div className="row">
                    <div className="input-field col s12 m4 l2">
                        <select ref={(el) => dropdownRefs.current.reportType = el} id="reportType" value={reportData.reportType} onChange={dataOnChange}>
                            <option value="INITIAL">Initial Notification</option>
                            <option value="FOLLOWUP">Follow-Up Notification</option>
                            <option value="TERMINATE">Request for Termination</option>
                        </select>
                        <label htmlFor="reportType">Type of Report</label>
                    </div>
                    <div className="input-field col s12 m4 l2">
                        <input id="incidentNumber" type="text" value={reportData.incidentNumber || ''} onChange={dataOnChange} disabled={reportData.reportType === 'initial'} />
                        <label htmlFor="incidentNumber">Incident Number</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s12 m4 l3">
                        <input id="initialNotificationDate" type="date" value={reportData.initialNotificationDate || ''} onChange={dataOnChange} />
                        <label htmlFor="initialNotificationDate">Date of Initial Notification</label>
                    </div>
                    <div className="input-field col s12 m4 l3">
                        <input id="productFoundDate" type="date" value={reportData.productFoundDate || ''} onChange={dataOnChange} />
                        <label htmlFor="productFoundDate">Date Product Determined Illegitimate</label>
                    </div>
                    <div className="input-field col s12 m4 l3">
                        <select ref={(el) => dropdownRefs.current.classification = el} id="classification" value={reportData.classification} onChange={dataOnChange}>
                            <option value="COUNTERFEIT">Counterfeit</option>
                            <option value="DIVERTED">Diverted</option>
                            <option value="STOLEN">Stolen</option>
                            <option value="INTENTIONAL ADULTERATION">Intentional Adulteration</option>
                            <option value="UNFIT FOR DISTRIBUTION for distribution">Unfit for Distribution</option>
                            <option value="FRAUDULENT TRANSACTION">Fraudulent Transaction</option>
                        </select>
                        <label htmlFor="classification">Classification of Notification</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s12 l9">
                        <input id="nameOfProduct" type="text" value={reportData.nameOfProduct || ''} onChange={dataOnChange} />
                        <label htmlFor="nameOfProduct">Name of Product as It Appears on Label</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s12">
                        <input id="primaryIngredients" type="text" value={reportData.primaryIngredients || ''} onChange={dataOnChange} />
                        <label htmlFor="primaryIngredients">Primary Ingredients</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s6">
                        <select ref={(el) => dropdownRefs.current.use = el} id="use" value={reportData.use} onChange={dataOnChange}>
                            <option value="HUMAN USE">Human Use</option>
                            <option value="OTHER">Other</option>
                        </select>
                        <label htmlFor="use">Drug Use</label>
                    </div>
                    <div className="input-field col s6">
                        <select ref={(el) => dropdownRefs.current.description = el} id="description" value={reportData.description} onChange={dataOnChange}>
                            <option value="FINISHED PRESCRIPTION DRUG">Finished Prescription Drug</option>
                            <option value="VACCINE">Vaccine</option>
                            <option value="PLASMA DERIVATIVE">Plasma Derivative (Coagulation Factors, Immunoglobulins, Albumin)</option>
                            <option value="ALLERGENIC">Allergenic (Standardized and Non-Standardized)</option>
                            <option value="MULTIPLE">Multiple</option>
                        </select>
                        <label htmlFor="description">Drug Description</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s12 m3">
                        <input id="strength" type="text" value={reportData.strength || ''} onChange={dataOnChange} />
                        <label htmlFor="strength">Strength of Drug</label>
                    </div>
                    <div className="input-field col s12 m3">
                        <select ref={(el) => dropdownRefs.current.dosageForm = el} id="dosageForm" value={reportData.dosageForm || ''} onChange={dataOnChange}>
                            <option value="TABLET">Tablet</option>
                            <option value="CAPSULE">Capsule</option>
                            <option value="SUBLINGUAL">Sublingual</option>
                            <option value="AEROSOL">Aerosol</option>
                            <option value="ORAL LIQUID">Oral Liquid</option>
                            <option value="INJECTABLE">Injectable</option>
                            <option value="TOPICAL">Topical</option>
                            <option value="SUPPOSITORY">Suppository</option>
                            <option value="OTHER">Other</option>
                            <option value="MULTIPLE">Multiple</option>
                        </select>
                        <label htmlFor="dosageForm">Dosage Form</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s12 m6">
                        <input id="quantity" type="text" value={reportData.quantity || ''} onChange={dataOnChange} />
                        <label htmlFor="quantity">Quantity of Drug (Number and Unit)</label>
                    </div>
                    <div className="input-field col s12 m3">
                        <input id="ndc" type="text" value={reportData.ndc || ''} onChange={dataOnChange} />
                        <label htmlFor="ndc">NDC (if applicable)</label>
                    </div>
                    <div className="input-field col s12 m3">
                        <input id="serialNumber" type="text" value={reportData.serialNumber || ''} onChange={dataOnChange} />
                        <label htmlFor="serialNumber">Serial Number (if applicable)</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s12">
                        <input id="lotNumber" type="text" value={reportData.lotNumber || ''} onChange={dataOnChange} />
                        <label htmlFor="lotNumber">Lot Number(s)</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s12">
                        <input id="expiration" type="text" value={reportData.expiration || ''} onChange={dataOnChange} />
                        <label htmlFor="expiration">Expiration(s)</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s12">
                        <textarea id="eventNotes" className="materialize-textarea text-area-blue" value={reportData.eventNotes || ''} onChange={dataOnChange} />
                        <label htmlFor="eventNotes" className="text-area-blue">For Notification: Description of Event/Issue</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s12">
                        <textarea id="terminationNotes" className="materialize-textarea text-area-blue" value={reportData.terminationNotes || ''} onChange={dataOnChange} />
                        <label htmlFor="terminationNotes" className="text-area-blue">For Request For Termination of Notification: Description of why notification is no longer necessary</label>
                    </div>
                </div>
                <div className="divider" />
                <div className="row">
                    <h6>If you have submitted information to FDA through an alternative mechanism, check all that apply.</h6>
                    <p>
                        <label>
                            <input id="bpdr" type="checkbox" className="filled-in" checked={reportData.bpdr ? 'checked' : ''} onChange={dataOnChange} />
                            <span>BPDR</span>
                        </label>
                    </p>
                    <p>
                        <label>
                            <input id="far" type="checkbox" className="filled-in" checked={reportData.far ? 'checked' : ''} onChange={dataOnChange} />
                            <span>FAR</span>
                        </label>
                    </p>
                    <p>
                        <label>
                            <input id="medWatch3500" type="checkbox" className="filled-in" checked={reportData.medWatch3500 ? 'checked' : ''} onChange={dataOnChange} />
                            <span>MedWatch 3500</span>
                        </label>
                    </p>
                    <p>
                        <label>
                            <input id="medWatch3500a" type="checkbox" className="filled-in" checked={reportData.medWatch3500a ? 'checked' : ''} onChange={dataOnChange} />
                            <span>MedWatch 3500A</span>
                        </label>
                    </p>
                    <div style={{ display: 'flex' }}>
                        <p style={{ marginRight: '20px' }}>
                            <label>
                                <input id="other" type="checkbox" className="filled-in" checked={reportData.other ? 'checked' : ''} onChange={dataOnChange} />
                                <span>Other</span>
                            </label>
                        </p>
                        <div className="input-field col s12" style={{ position: 'relative', bottom: '18px' }}>
                            <input id="otherSpecify" type="text" value={reportData.otherSpecify || ''} onChange={dataOnChange} disabled={!reportData.other} />
                            <label htmlFor="otherSpecify">Specify</label>
                        </div>
                    </div>
                    <p>
                        <label>
                            <input id="none" type="checkbox" className="filled-in" checked={reportData.none ? 'checked' : ''} onChange={dataOnChange} />
                            <span>None</span>
                        </label>
                    </p>
                </div>
                <div className="divider" />
                <h5>Company/Facility Information</h5>
                <div className="row">
                    <div className="input-field col s12">
                        <input id="companyName" type="text" value={reportData.companyName || ''} onChange={dataOnChange} />
                        <label htmlFor="companyName">Company Name</label>
                    </div>

                </div>
                <div className="row">
                    <div className="input-field col s12">
                        <input id="addressLineOne" type="text" value={reportData.addressLineOne || ''} onChange={dataOnChange} />
                        <label htmlFor="addressLineOne">Address 1</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s12">
                        <input id="addressLineTwo" type="text" value={reportData.addressLineTwo || ''} onChange={dataOnChange} />
                        <label htmlFor="addressLineTwo">Address 2</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s6">
                        <input id="city" type="text" value={reportData.city || ''} onChange={dataOnChange} />
                        <label htmlFor="city">City</label>
                    </div>
                    <div className="input-field col s6">
                        <input id="state" type="text" value={reportData.state || ''} onChange={dataOnChange} />
                        <label htmlFor="state">State/Province/Region</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s8">
                        <input id="country" type="text" value={reportData.country || ''} onChange={dataOnChange} />
                        <label htmlFor="country">Country</label>
                    </div>
                    <div className="input-field col s4">
                        <input id="zip" type="text" value={reportData.zip || ''} onChange={dataOnChange} />
                        <label htmlFor="zip">ZIP or Postal Code</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s12">
                        <select ref={(el) => dropdownRefs.current.companyCategory = el} id="companyCategory" value={reportData.companyCategory || ''} onChange={dataOnChange}>
                            <option value="MANUFACTURER">Manufacturer</option>
                            <option value="WHOLESALE DISTRIBUTOR">Wholesale Distributor</option>
                            <option value="DISPENSER (PHARMACY)">Dispenser (Pharmacy)</option>
                            <option value="REPACKAGER">Repackager</option>
                        </select>
                        <label htmlFor="companyCategory">Company Category</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s12">
                        <input id="uniqueFacilityIdentifier" type="text" value={reportData.uniqueFacilityIdentifier || ''} onChange={dataOnChange} />
                        <label htmlFor="uniqueFacilityIdentifier">Unique Facility Identifier</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s6">
                        <input id="contactName" type="text" value={reportData.contactName || ''} onChange={dataOnChange} />
                        <label htmlFor="contactName">Contact Name</label>
                    </div>
                    <div className="input-field col s6">
                        <input id="contactPhone" type="text" value={reportData.contactPhone || ''} onChange={dataOnChange} />
                        <label htmlFor="contactPhone">Contact Phone (Include area code)</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s12">
                        <input id="contactEmail" type="text" value={reportData.contactEmail || ''} onChange={dataOnChange} />
                        <label htmlFor="contactEmail">Contact Email</label>
                    </div>
                </div>
                <div className="row">
                    <div className="col s12 m2">
                        <a
                            className="btn-small blue white-text waves-effect waves-light col s12"
                            href="/"
                            onClick={submitIncident}
                            disabled={generating}
                        >
                            {generating ? 'Generating...' : 'Preview'}</a>
                    </div>
                </div>
            </div>
            <div ref={modalRef} id="form-preview-modal" className="modal large-modal" style={{ zIndex: '999' }}>
                <div className="modal-content" style={{ height: '85vh' }}>
                    <div className="row">
                        <h5>Does this document look correct?</h5>
                    </div>
                    <div className="divider" />
                    <embed
                        id="pdf-frame"
                        src={pdfUrl}
                        title="PDF Preview"
                        width="100%"
                        height="90%"
                        type="application/pdf"
                        style={{ border: 'none' }}
                    />
                </div>
                <div className="row">
                    <button
                        className="btn-small green white-text waves-effect waves-light col s3 offset-s2"
                        disabled={submitting ? true : false}
                        onClick={saveDocument}>
                        {submitting ? 'Sending...' : 'Submit'}
                    </button>
                    <button
                        className="btn-small red white-text waves-effect waves-light col s3 offset-s2"
                        disabled={submitting ? true : false}
                        onClick={closeModal}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ReportAnIncident;