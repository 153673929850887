import endpoints from '../endpoints.ts';
import { IllegitReport, IllegitReportDisplayKeys } from "../types/models";
import { fetch } from './helpers.ts';

const illegitReport = {
  create: {
    one: async (data: Partial<IllegitReport>): Promise<ArrayBuffer | null> => {
        const url = endpoints.illegitReport.create.one();
        return await fetch<ArrayBuffer>(url, 'POST', data, 'arraybuffer');
    },
    send: async (data: FormData): Promise<null> => {
      const url = endpoints.illegitReport.create.send();
      return await fetch<null>(url, 'POST', data);
    }
  },
  read: {
    many: {
        by:{
            search: async (data: IllegitReportDisplayKeys, AbortSignal: AbortSignal): Promise<Partial<IllegitReport>[] | null> => {
              const url = endpoints.illegitReport.read.many.by.search();
              return await fetch<Partial<IllegitReport>[]>(url, 'GET', data, 'json', AbortSignal)
            }
        }
    },
    count: {
      by: {
        userId: async (): Promise<Number | null> => {
            const url =
              endpoints.illegitReport.read.count.by.userId();
            return await fetch<Number>(url, 'GET');
          },
      }
    },
    one: {
      by: {
        id: async (data: { id: number }): Promise<IllegitReport | null> => {
          const url = endpoints.illegitReport.read.one.by.id();
          return await fetch<IllegitReport | null>(url, 'GET', data);
        }
      }
    }
  },
  update: {
    one: {
      by: {
        id: async (data: Partial<IllegitReport>): Promise<null> => {
          const url = endpoints.illegitReport.update.one.by.id();
          return await fetch<null>(url, 'POST', data);
        }
      }
    }
  },
  delete: {},
  download: {
    one: {
      by: {
        path: async (data: { filepath: string }): Promise<Buffer | null> => {
          const url = endpoints.illegitReport.download.one.by.path();
          return await fetch<Buffer | null>(url, 'GET', data, "arraybuffer");
        }
      }
    }
  }
};

export default illegitReport;
