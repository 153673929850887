import { useQuery } from '@tanstack/react-query';
import React, { MouseEvent, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import endpoints from '../../common/endpoints.ts';
import api from '../../common/api/index.ts';
import DisplayTable from '../../components/DisplayTable.tsx';
import {
  CustomerLocationAddress,
  DisplayTableData,
} from '../../common/types/models.ts';
import EditAddressModal from './modals/EditAddressModal.tsx';
import DeleteAddressModal from './modals/DeleteAddressModal.tsx';
import AddAddressModal from './modals/AddAddressModal.tsx';
import { MdModeEditOutline } from 'react-icons/md';
import EditNameModal from './modals/EditNameModal.tsx';

const LocationDetail = () => {
  const { id: locationId } = useParams();
  const [isOpenAddressModal, setIsOpenAddressModal] = useState<boolean>(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const [isOpenAddModal, setIsOpenAddModal] = useState<boolean>(false);
  const [isOpenEditNameModal, setIsOpenEditNameModal] =
    useState<boolean>(false);
  const [selectedAddress, setSelectedAddress] =
    useState<Partial<CustomerLocationAddress>>();
  const [globalLocationNumberData, setGlobalLocationNumberData] =
    useState<{ id: number; data: DisplayTableData[] }[]>();
  const [addressData, setAddressData] =
    useState<{ id: number | string; data: DisplayTableData[] }[]>();

  const { data: location } = useQuery({
    queryKey: [
      endpoints.customerLocation.read.one.hydrated.by.id(),
      locationId,
    ],
    queryFn: () =>
      api.customerLocation.read.one.hydrated.by.id(locationId || ''),
    refetchOnMount: true,
  });

  const editAddress = (
    event: MouseEvent<HTMLAnchorElement>,
    address: Partial<CustomerLocationAddress>
  ) => {
    event?.preventDefault();
    setIsOpenAddressModal(true);
    setSelectedAddress(address);
  };

  const deleteAddress = (
    event: MouseEvent<HTMLAnchorElement>,
    address: Partial<CustomerLocationAddress>
  ) => {
    event?.preventDefault();
    setIsOpenDeleteModal(true);
    setSelectedAddress(address);
  };

  useEffect(() => {
    setGlobalLocationNumberData(
      location?.customerLocationGlobalLocationNumbers?.map(
        ({
          isVerified,
          disposition,
          globalLocationNumber: { id, globalLocationNumber },
        }) => ({
          id,
          data: [
            {
              label: 'GLN',
              value: globalLocationNumber,
            },
            { label: 'Disposition', value: disposition.toString() },
            {
              label: 'Verified',
              value: (
                <span className={isVerified ? 'green-text' : 'orange-text'}>
                  {isVerified ? 'VERIFIED' : 'PENDING'}
                </span>
              ),
            },
          ],
        })
      )
    );

    setAddressData(
      location?.customerLocationAddresses?.map(addr => ({
        id: addr.id,
        data: [
          {
            label: 'Address Line 1',
            value: (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span style={{ flex: 2 }}>{addr.addressLineOne}</span>
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'space-around',
                  }}
                >
                  <a href="/" onClick={e => editAddress(e, addr)}>
                    Edit
                  </a>
                  {location.customerLocationAddresses.length > 1 ? (
                    <a href="/" onClick={e => deleteAddress(e, addr)}>
                      Delete
                    </a>
                  ) : null}
                </div>
              </div>
            ),
          },
          { label: 'Address Line 2', value: addr.addressLineTwo },
          { label: 'Address Line 3', value: addr.addressLineThree },
          { label: 'City', value: addr.city },
          { label: 'State', value: addr.state.name },
          { label: 'Postal Code', value: addr.zip },
          { label: 'Type', value: addr.type },
          {
            label: 'Default Address',
            value:
              addr.id === location.customerLocationDefaultAddress?.id
                ? 'Yes'
                : 'No',
          },
        ],
      }))
    );
  }, [location]);

  return location ? (
    <div>
      <div className="row">
        <h5>
          {location?.name}{' '}
          <MdModeEditOutline
            className="blue-text"
            style={{ fontSize: '.8em', cursor: 'pointer' }}
            onClick={() => setIsOpenEditNameModal(true)}
          />
        </h5>
      </div>
      <div className="row">
        <h5 className="center">Global Location Numbers</h5>
        {globalLocationNumberData?.length ? (
          globalLocationNumberData?.map(({ id, data }) => (
            <div key={id} className="row" style={{ marginBottom: '40px' }}>
              <DisplayTable data={data} />
            </div>
          ))
        ) : (
          <div className="row">
            <span>
              No global location numbers exist for this location.{' '}
              <Link to="/gln">Add GLN</Link>
            </span>
          </div>
        )}
      </div>
      <div className="row">
        <h5 className="center">Addresses</h5>
        {addressData?.map(({ id, data }) => (
          <div key={id} className="row" style={{ marginBottom: '40px' }}>
            <DisplayTable data={data} />
          </div>
        ))}
      </div>
      <div className="row">
        <div className="col s12 m4 offset-m8">
          <a
            href="/"
            className="btn-small blue white-text waves-effect waves-light col s12"
            onClick={event => {
              event.preventDefault();
              setIsOpenAddModal(true);
            }}
          >
            Add Address
          </a>
        </div>
      </div>
      <EditAddressModal
        isOpen={isOpenAddressModal}
        address={selectedAddress}
        setIsOpen={setIsOpenAddressModal}
        defaultAddressId={location?.customerLocationDefaultAddress?.id}
      />
      <DeleteAddressModal
        isOpen={isOpenDeleteModal}
        setIsOpen={setIsOpenDeleteModal}
        address={selectedAddress}
      />
      <AddAddressModal
        isOpen={isOpenAddModal}
        setIsOpen={setIsOpenAddModal}
        customerLocationId={location.id}
      />
      <EditNameModal
        isOpen={isOpenEditNameModal}
        setIsOpen={setIsOpenEditNameModal}
        location={location}
      />
    </div>
  ) : (
    <div className="progress">
      <div className="indeterminate" />
    </div>
  );
};

export default LocationDetail;
