import endpoints from '../endpoints.ts';
import { GLNDisposition } from '../enums.ts';
import { CustomerLocationGlobalLocationNumber, Toast } from '../types/models.ts';
import { fetch } from './helpers.ts';

const customerLocationGlobalLocationNumber = {
  create: {
    one: async (data: {
      globalLocationNumber: string;
      customerLocationId: number;
      disposition: GLNDisposition;
    }): Promise<Toast | null> => {
      const url = endpoints.customerLocationGlobalLocationNumber.create.one();
      return await fetch<Toast>(url, 'POST', data);
    },
  },
  read: {
    many: {
      by: {
        userId: async (): Promise<
          CustomerLocationGlobalLocationNumber[] | null
        > => {
          const url =
            endpoints.customerLocationGlobalLocationNumber.read.many.by.userId();
          return await fetch<CustomerLocationGlobalLocationNumber[]>(
            url,
            'GET'
          );
        },
      },
    },
  },
  update: {
    one: async (data: {
      newGlobalLocationNumber: string;
      disposition: GLNDisposition | '';
      customerLocationId: number;
      globalLocationNumberId: number;
    }): Promise<Toast | null> => {
      const url = endpoints.customerLocationGlobalLocationNumber.update.one();
      return await fetch<Toast>(url, 'PUT', data);
    },
  },
  delete: {
    one: async (data: {
      customerLocationId: number;
      globalLocationNumberId: number;
    }): Promise<Toast | null> => {
      const url = endpoints.customerLocationGlobalLocationNumber.delete.one();
      return await fetch<Toast>(url, 'DELETE', data);
    },
  },
};

export default customerLocationGlobalLocationNumber;
