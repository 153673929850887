import Axios from 'axios';
import { useEffect, useLayoutEffect, useRef, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import medTraxLogo from '../../assets/medtrax_logo.png';

const UsernameAndPassword = (props) => {
  const { setFirstFactor } = props;

  const [values, setValues] = useState({
    emailAddress: '',
    password: '',
  });

  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onChange = ({ target: { id, value } }) => {
    setValues((p) => ({ ...p, [id]: value }));
    setErrorMessage('');
  };

  const ref = useRef({
    values: null,
  });

  const submit = useCallback((e) => {
    e?.preventDefault();

    setSubmitting(true);

    Axios.post('/api/v1/auth/create/one/token', ref.current.values)
      .then((result) => {
        localStorage.setItem(
          'auth_data',
          JSON.stringify({
            ...result.data,
            user_timezone:
              Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ||
              'America/Los_Angeles',
          })
        );
        setFirstFactor(true);
      })
      .catch((err) =>
        setErrorMessage(
          err?.response?.data?.payload?.msg || 'An Unknown Error Occurred'
        )
      )
      .finally(() => setSubmitting(false));
  }, [setFirstFactor]);

  useLayoutEffect(() => {
    const enterListener = ({ keyCode }) => {
      if (keyCode === 13) submit();
    };

    document.addEventListener('keydown', enterListener);
    return () => document.removeEventListener('keydown', enterListener);
  }, [submit]);

  useEffect(() => {
    ref.current.values = values;
  }, [values]);

  return (
    <div>
      <div className="row">
        <img src={medTraxLogo} alt="MedTrax Logo" style={{ maxHeight: '80px', margin: '20px' }} />
      </div>
      <div className="row container">
        <div
          className="col s12 m10 offset-m1 l8 offset-l2 xl6 offset-xl3"
          style={{ marginTop: '15vh' }}
        >
          <div className="card">
            <div className="card-content" style={{ minHeight: '40vh' }}>
              <div className="row" style={{ marginBottom: '60px' }}>
                <h4 className="center blue-grey-text text-darken-1">
                  MedTrax User Login
                </h4>
              </div>
              <div className="row">
                <div className="input-field col s10 offset-s1">
                  <input
                    id="emailAddress"
                    type="text"
                    value={values.emailAddress}
                    onChange={onChange}
                  />
                  <label htmlFor="emailAddress">Email Address</label>
                </div>
                <div className="input-field col s10 offset-s1">
                  <input
                    id="password"
                    type="password"
                    value={values.password}
                    onChange={onChange}
                  />
                  <label htmlFor="password">Password</label>
                </div>
                <div className="input-field col s12">
                  <div className="col s12 m8 offset-m2">
                    <a
                      href="/"
                      className="btn white blue-text waves-effect waves-dark col s12"
                      onClick={submit}
                      disabled={submitting}
                    >
                      {submitting ? 'Logging In...' : 'Submit'}
                    </a>
                  </div>
                </div>
              </div>
              <div className="row">
                <p className="red-text center" style={{}}>
                  {errorMessage}
                </p>
              </div>
            </div>
            <Link
              className="right"
              style={{ paddingRight: '20px', paddingTop: '8px' }}
              to="/forgotpassword"
            >
              Forgot Password?
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsernameAndPassword;
