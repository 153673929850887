import React, {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {
  TradingPartner,
  TradingPartnerGlobalLocationNumber,
} from '../../../common/models';
import DisplayTable from '../../../components/DisplayTable.tsx';
import api from '../../../common/api/index.ts';

interface props {
  tradingPartner: TradingPartner;
  reload: () => void;
}

const EditGlobalLocationNumbers = ({ reload, tradingPartner }: props) => {
  const editModal = useRef<HTMLDivElement>(null);
  const addModal = useRef<HTMLDivElement>(null);

  const getAddModal = (): M.Modal | undefined =>
    addModal.current ? M.Modal.getInstance(addModal.current) : undefined;

  const getEditModal = (): M.Modal | undefined =>
    editModal.current ? M.Modal.getInstance(editModal.current) : undefined;

  const initValues = () => ({
    add: '',
    edit: '',
  });

  const [values, setValues] = useState(initValues());
  const [selectedGlobalLocationNumberId, setSelectedGlobalLocationNumberId] =
    useState<number>();

  useLayoutEffect(() => {
    const { current: editElement } = editModal;
    const { current: addElement } = addModal;
    if (addElement) M.Modal.init(addElement);
    if (editElement)
      M.Modal.init(editElement, { onOpenEnd: M.updateTextFields });

    return () => {
      getAddModal()?.destroy();
      getEditModal()?.destroy();
    };
  }, []);

  const deleteGln = async (
    event: MouseEvent<HTMLAnchorElement>,
    gln: TradingPartnerGlobalLocationNumber
  ) => {
    event?.preventDefault();
    if (
      gln.id &&
      window.confirm(
        `Are you sure you want to delete GLN: ${gln.globalLocationNumber} from ${tradingPartner?.name}?`
      )
    )
      await api.tradingPartner.delete.one.tradingPartnerGlobalLocationNumber(
        gln.id
      );
    reload();
  };

  const editGln = (
    event: MouseEvent<HTMLAnchorElement>,
    gln: TradingPartnerGlobalLocationNumber
  ) => {
    event?.preventDefault();
    setValues({ ...initValues(), edit: gln.globalLocationNumber });
    getEditModal()?.open();
    setSelectedGlobalLocationNumberId(gln.id);
  };

  const getGLNActions = (gln: TradingPartnerGlobalLocationNumber) => (
    <>
      <a href="/" onClick={(event) => editGln(event, gln)}>
        Edit
      </a>
      <a href="/" onClick={(event) => deleteGln(event, gln)}>
        Delete
      </a>
    </>
  );

  const addGlobalLocationNumber = (event: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    getAddModal()?.open();
    setValues(initValues());
  };

  const onChange = ({ target: { id, value } }: ChangeEvent<HTMLInputElement>) =>
    setValues((prev) => ({ ...prev, [id]: value }));

  const updateGlobalLocationNumber = useCallback(
    async (event: MouseEvent<HTMLAnchorElement>) => {
      event?.preventDefault();
      if (selectedGlobalLocationNumberId)
        await api.tradingPartner.update.one.tradingPartnerGlobalLocationNumber({
          id: selectedGlobalLocationNumberId,
          globalLocationNumber: values.edit,
        });
      reload();
      getEditModal()?.close();
    },
    [values.edit, selectedGlobalLocationNumberId, reload]
  );

  const createGlobalLocationNumber = useCallback(
    async (event: MouseEvent<HTMLAnchorElement>) => {
      event?.preventDefault();
      await api.tradingPartner.create.tradingPartnerGlobalLocationNumber({
        tradingPartnerId: tradingPartner.id,
        globalLocationNumber: values.add,
      });
      reload();
      getAddModal()?.close();
    },
    [values.add, tradingPartner, reload]
  );

  const cancel = (event: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    const { current: editElement } = editModal;
    const { current: addElement } = addModal;
    if (editElement) M.Modal.getInstance(editElement)?.close();
    if (addElement) M.Modal.getInstance(addElement)?.close();

    setValues(initValues());
  };

  return (
    <>
      <div className="row" style={{ marginTop: '40px' }}>
        <div style={{ marginBottom: '20px' }}>
          <h5>Global Location Numbers</h5>
          <a href="/" onClick={(event) => addGlobalLocationNumber(event)}>
            Add Global Location Number
          </a>
        </div>
        <div className="row">
          {tradingPartner.globalLocationNumbers &&
          tradingPartner.globalLocationNumbers.length ? (
            <DisplayTable
              data={tradingPartner.globalLocationNumbers.map((gln) => ({
                label: 'GLN',
                value: (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    {gln.globalLocationNumber}
                    <div
                      style={{
                        display: 'flex',
                        width: '120px',
                        justifyContent: 'space-between',
                      }}
                    >
                      {getGLNActions(gln)}
                    </div>
                  </div>
                ),
              }))}
            />
          ) : (
            <>
              <div className="divider" />
              <p className="center">No Global Location Numbers to display</p>
            </>
          )}
        </div>
      </div>
      <div id="gln-add-modal" className="modal" ref={addModal}>
        <div className="modal-content">
          <div className="row">
            <h6 className="center">Add Global Location Number</h6>
          </div>
          <div className="row">
            <div className="input-field col s12 m5">
              <input
                id="add"
                type="text"
                value={values.add}
                onChange={onChange}
              />
                  <label htmlFor="add">Global Location Number</label>
          
            </div>
          </div>
          <div className="row">
            <div className="col s6 l3 offset-l6">
              <a
                href="/"
                className="btn-small blue white-text waves-effect waves-light col s12"
                onClick={createGlobalLocationNumber}
              >
                Add GLN
              </a>
            </div>
            <div className="col s6 l3">
              <a
                href="/"
                className="btn-small blue white-text waves-effect waves-light col s12"
                onClick={cancel}
              >
                Cancel
              </a>
            </div>
          </div>
        </div>
      </div>
      <div id="gln-edit-modal" className="modal" ref={editModal}>
        <div className="modal-content">
          <div className="row">
            <h6 className="center">Edit Global Location Number</h6>
          </div>
          <div className="row">
            <div className="input-field col s12 m5">
              <input
                id="edit"
                type="text"
                value={values.edit}
                onChange={onChange}
              />
              <label htmlFor="edit">Global Location Number</label>
            </div>
          </div>
          <div className="row">
            <div className="col s6 l3 offset-l6">
              <a
                href="/"
                className="btn-small blue white-text waves-effect waves-light col s12"
                onClick={updateGlobalLocationNumber}
              >
                Update GLN
              </a>
            </div>
            <div className="col s6 l3">
              <a
                href="/"
                className="btn-small blue white-text waves-effect waves-light col s12"
                onClick={cancel}
              >
                Cancel
              </a>
            </div>
          </div>
        </div>{' '}
      </div>
    </>
  );
};

export default EditGlobalLocationNumbers;
