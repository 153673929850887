import React, { MouseEvent, useEffect, useState } from 'react';
import Addresses from './Addresses.tsx';
import Contacts from './Contacts.tsx';
import AccountNumbers from './AccountNumbers.tsx';
import {
  CustomerLocation,
  TradingPartner,
  TradingPartnerAddress,
  TradingPartnerContact,
  TradingPartnerAccountNumber,
  TradingPartnerGlobalLocationNumber,
} from '../../../common/types/models.ts';
import { TradingPartnerType } from '../../../common/enums.ts';
import GlobalLocationNumbers from './GlobalLocationNumbers.tsx';
import api from '../../../common/api/index.ts';
import { useNavigate } from 'react-router-dom';

const General = () => {
  const navigate = useNavigate();
  const initValues = (): Partial<TradingPartner> => ({
    customerLocationId: undefined,
    name: '',
    type: TradingPartnerType.upstream,
  });

  const [values, setValues] = useState<Partial<TradingPartner>>(initValues());
  const [globalLocationNumbers, setGlobalLocationNumbers] = useState<
    Partial<TradingPartnerGlobalLocationNumber>[]
  >([]);
  const [addresses, setAddresses] = useState<Partial<TradingPartnerAddress>[]>(
    []
  );
  const [contacts, setContacts] = useState<Partial<TradingPartnerContact>[]>(
    []
  );
  const [accountNumbers, setAccountNumbers] = useState<
    Partial<TradingPartnerAccountNumber>[]
  >([]);
  const [customerLocationList, setCustomerLocationList] = useState<
    CustomerLocation[]
  >([]);

  const onChange = ({ target: { id, value } }) =>
    setValues((prev) => ({ ...prev, [id]: value }));

  const createTradingPartner = async (event: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    const payload = {
      ...values,
      globalLocationNumbers,
      addresses,
      contacts,
      accountNumbers,
    };

    const response = await api.tradingPartner.create.one(
      payload as TradingPartner
    );
    if (response) navigate('/tradingpartner');
  };

  const getCustomerLocations = async () => {
    const customerLocations = await api.customer.read.one.by.userId();
    if (customerLocations) {
      setCustomerLocationList(customerLocations);
      setValues((prev) => ({
        ...prev,
        customerLocationId: customerLocations[0]?.id,
      }));
      M.FormSelect.init(document.querySelectorAll('#customerLocationId'));
    }
  };

  useEffect(() => {
    getCustomerLocations();
  }, []);

  return (
    <div>
      <div className="row">
        <h5>New Trading Partner</h5>
        <div className="divider" />
      </div>
      {customerLocationList?.length > 1 ? (
        <div className="row">
          <div className="input-field col s12 m5">
            <select
              id="customerLocationId"
              value={values.customerLocationId}
              onChange={onChange}
            >
              {customerLocationList?.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </select>
            <label htmlFor="customerLocationId">Your Location</label>
          </div>
        </div>
      ) : null}
      <div className="row">
        <div className="input-field col s12 m6">
          <input
            id="name"
            type="text"
            onChange={onChange}
            value={values.name}
          />
          <label htmlFor="name">Trading Partner Name</label>
        </div>
        <div className="input-field col s12 m4">
          <select id="type" value={values.type} onChange={onChange}>
            <option value="upstream">Upstream</option>
            <option value="downstream">Downstream</option>
            <option value="both">Both</option>
          </select>
          <label htmlFor="type">Trading Partner Type</label>
        </div>
      </div>
      <div className="row" style={{ marginBottom: '40px' }}>
        <GlobalLocationNumbers
          globalLocationNumbers={globalLocationNumbers}
          setGlobalLocatoinNumbers={setGlobalLocationNumbers}
        />
      </div>
      <div className="row" style={{ marginBottom: '40px' }}>
        <Addresses addresses={addresses} setAddresses={setAddresses} />
      </div>
      <div className="row" style={{ marginBottom: '40px' }}>
        <Contacts contacts={contacts} setContacts={setContacts} />
      </div>
      <AccountNumbers
        accountNumbers={accountNumbers}
        setAccountNumbers={setAccountNumbers}
      />
      <div className="row">
        <div className="input-field col s12 m4">
          <a
            href="/"
            className="btn-small blue white-text waves-effect waves-light col s12"
            onClick={createTradingPartner}
          >
            Create Trading Partner
          </a>
        </div>
      </div>
    </div>
  );
};

export default General;
