import React, {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useLayoutEffect,
  useRef,
} from 'react';
import { CustomerLocationAddress } from '../../../common/types/models.ts';
import {
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import endpoints from '../../../common/endpoints.ts';
import api from '../../../common/api/index.ts';

interface IProps {
  isOpen: boolean;
  address?: Partial<CustomerLocationAddress>;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const DeleteAddressModal = ({ isOpen, address, setIsOpen }: IProps) => {
  const queryClient = useQueryClient();
  const modal = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const { current } = modal;
    if (current)
      M.Modal.init(current, {
        dismissible: false,
        onCloseEnd: () => setIsOpen(false),
      });

    return () => {
      if (current) M.Modal.getInstance(current)?.destroy();
    };
  }, []);

  const getModal = () =>
    modal.current ? M.Modal.getInstance(modal.current) : null;

  useLayoutEffect(() => {
    const modal = getModal();
    if (modal) isOpen ? modal.open() : modal.close();
  }, [isOpen]);

  const cancel = (event?: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    getModal()?.close();
  };

  const { mutate: deleteCustomerLocationAddress } = useMutation({
    mutationKey: [endpoints.customerLocation.delete.one.address(), address],
    mutationFn: () => api.customerLocation.delete.one.address(address?.id || 0),
    onSuccess: data => {
      queryClient.invalidateQueries({
        queryKey: [endpoints.customerLocation.read.one.hydrated.by.id()],
      });
      if (data) cancel();
    },
  });

  const deleteAddress = (event: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    deleteCustomerLocationAddress();
  };

  return (
    <div ref={modal} className="modal">
      <div className="modal-content">
        <div className="row">
          <h5>
            Are you sure you want to delete location{' '}
            <span className="red-text">{address?.addressLineOne}</span>?
          </h5>
        </div>
        <div className="row">
          <div className="input-field col s12 m4 right">
            <a
              href="/"
              className="btn-small red white-text waves-effect waves-light col s12"
              onClick={deleteAddress}
            >
              Delete Address
            </a>
          </div>
          <div className="input-field col s12 m4 left">
            <a
              href="/"
              className="btn-small blue white-text waves-effect waves-light col s12"
              onClick={cancel}
            >
              cancel
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteAddressModal;
