import { Toast } from 'materialize-css';
import endpoints from '../endpoints.ts';
import { HydratedCustomerLocation } from '../types/compoundModels.ts';
import { CustomerLocation } from '../types/models.ts';
import { fetch } from './helpers.ts';
import { CustomerLocationAddressType } from '../enums.ts';

const customerLocation = {
  create: {
    one: async (
      addresses: {
        addressLineOne?: string;
        addressLineTwo: string;
        addressLineThree?: string;
        city: string;
        stateId: string | number;
        zip: string;
        isDefault: boolean;
        type: CustomerLocationAddressType;
      }[]
    ): Promise<Toast | null> => {
      const url = endpoints.customerLocation.create.one();
      return await fetch<Toast>(url, 'POST', { addresses });
    },
    address: async (params: {
      customerLocationId: number | string;
      addressLineOne?: string;
      addressLineTwo: string;
      addressLineThree?: string;
      city: string;
      stateId: string | number;
      zip: string;
      isDefault: boolean;
      type: CustomerLocationAddressType;
    }): Promise<Toast | null> => {
      const url = endpoints.customerLocation.create.address();
      return await fetch<Toast>(url, 'POST', params);
    },
  },
  read: {
    one: {
      by: {
        id: async (id: number | string): Promise<CustomerLocation | null> => {
          const url = endpoints.customerLocation.read.one.by.id();
          return await fetch<CustomerLocation>(url, 'GET', { id });
        },
      },
      hydrated: {
        by: {
          id: async (
            id: number | string
          ): Promise<HydratedCustomerLocation | null> => {
            const url = endpoints.customerLocation.read.one.hydrated.by.id();
            return await fetch<HydratedCustomerLocation>(url, 'GET', { id });
          },
        },
      },
    },
    all: {
      by: {
        userId: async (): Promise<CustomerLocation[] | null> => {
          const url = endpoints.customerLocation.read.all.by.userId();
          return await fetch<CustomerLocation[]>(url, 'GET');
        },
      },
      hydrated: {
        by: {
          userId: async (): Promise<HydratedCustomerLocation[] | null> => {
            const url =
              endpoints.customerLocation.read.all.hydrated.by.userId();
            return await fetch<HydratedCustomerLocation[]>(url, 'GET');
          },
        },
      },
    },
  },
  update: {
    one: {
      address: async (params: {
        customerLocationAddressId: number | string;
        addressLineOne: string;
        addressLineTwo: string;
        addressLineThree?: string;
        city: string;
        stateId: number | string;
        zip: string;
        type: CustomerLocationAddressType;
        isDefault: boolean;
      }): Promise<Toast | null> => {
        const url = endpoints.customerLocation.update.one.address();
        return await fetch<Toast>(url, 'PUT', params);
      },
      name: async (params: {
        customerLocationId: string | number;
        name: string;
      }): Promise<Toast | null> => {
        const url = endpoints.customerLocation.update.one.name();
        return await fetch<Toast>(url, 'PUT', params);
      },
    },
  },
  delete: {
    one: {
      address: async (
        customerLocationAddressId: number | string
      ): Promise<Toast | null> => {
        const url = endpoints.customerLocation.delete.one.address();
        return await fetch<Toast>(url, 'DELETE', { customerLocationAddressId });
      },
    },
  },
};

export default customerLocation;
