import React, {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {
  State,
  TradingPartner,
  TradingPartnerAddress,
} from '../../../common/models';
import DisplayTable from '../../../components/DisplayTable.tsx';
import api from '../../../common/api/index.ts';
import { AddressType } from '../../../common/enums.ts';

interface props {
  tradingPartner: TradingPartner;
  reload: () => void;
}

const EditAddresses = ({ reload, tradingPartner }: props) => {
  const modal = useRef<HTMLDivElement>(null);

  const getModal = (): M.Modal | undefined =>
    modal.current ? M.Modal.getInstance(modal.current) : undefined;

  const initSelects = () => {
    M.FormSelect.init(document.querySelectorAll('#stateId'));
    M.FormSelect.init(document.querySelectorAll('#addressType'));
  };

  const initValues = () => ({
    stateId: 0,
    addressType: AddressType.physical,
    name: '',
    addressLineOne: '',
    addressLineTwo: '',
    city: '',
    postalCode: '',
  });

  const [stateList, setStateList] = useState<State[]>([]);
  const [values, setValues] = useState(initValues());
  const [mode, setMode] = useState<'ADD' | 'EDIT'>('ADD');
  const [selectedAddress, setSelectedAddress] =
    useState<TradingPartnerAddress>();

  const getStates = async () => {
    const stateList = await api.state.read.all();
    if (stateList) setStateList(stateList);
  };

  useEffect(() => {
    getStates();
  }, []);

  useLayoutEffect(() => {
    const onOpenEnd = () => {
      M.updateTextFields();
      initSelects();
    };

    const { current } = modal;
    if (current) M.Modal.init(current, { onOpenEnd });

    return () => {
      getModal()?.destroy();
      document.querySelectorAll('#stateId').forEach((el) => {
        if (el) M.FormSelect.getInstance(el)?.destroy();
      });
    };
  }, []);

  const deleteAddress = async (
    event: MouseEvent<HTMLAnchorElement>,
    address: TradingPartnerAddress
  ) => {
    event?.preventDefault();
    if (
      address.id &&
      window.confirm(
        `Are you sure you want to delete this address from ${tradingPartner?.name}?`
      )
    ) {
      await api.tradingPartner.delete.one.tradingPartnerAddress(address.id);
      reload();
    }
  };

  const editAddress = (
    event: MouseEvent<HTMLAnchorElement>,
    address: TradingPartnerAddress
  ) => {
    event?.preventDefault();
    setValues({ ...initValues(), ...address });
    setMode('EDIT');
    getModal()?.open();
    setSelectedAddress(address);
  };

  const getActions = (address: TradingPartnerAddress) => (
    <>
      <a href="/" onClick={(event) => editAddress(event, address)}>
        Edit
      </a>
      <a href="/" onClick={(event) => deleteAddress(event, address)}>
        Delete
      </a>
    </>
  );

  const addAddress = (event: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    setMode('ADD');
    getModal()?.open();
    setValues(initValues());
  };

  const onChange = ({
    target: { id, value },
  }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) =>
    setValues((prev) => ({ ...prev, [id]: value }));

  const updateAddress = useCallback(
    async (event: MouseEvent<HTMLAnchorElement>) => {
      event?.preventDefault();
      if (selectedAddress)
        await api.tradingPartner.update.one.tradingPartnerAddress({
          id: selectedAddress.id,
          stateId: values.stateId || null,
          addressType: values.addressType,
          name: values.name,
          addressLineOne: values.addressLineOne || null,
          addressLineTwo: values.addressLineTwo || null,
          city: values.city || null,
          postalCode: values.postalCode || null,
        });
      reload();
      getModal()?.close();
    },
    [values, selectedAddress, reload]
  );

  const createAddress = useCallback(
    async (event: MouseEvent<HTMLAnchorElement>) => {
      event?.preventDefault();
      await api.tradingPartner.create.tradingPartnerAddress({
        tradingPartnerId: tradingPartner.id,
        ...values,
      });
      reload();
      getModal()?.close();
    },
    [values, tradingPartner, reload]
  );

  const cancel = (event: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    getModal()?.close();
    setValues(initValues());
  };

  const getDisplayData = (address: TradingPartnerAddress) => [
    {
      label: 'name',
      value: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          {address.name}
          <div
            style={{
              display: 'flex',
              width: '120px',
              justifyContent: 'space-between',
            }}
          >
            {getActions(address)}
          </div>
        </div>
      ),
    },
    { label: 'Address Line One', value: address.addressLineOne },
    { label: 'Address Line Two', value: address.addressLineTwo },
    { label: 'City', value: address.city },
    { label: 'State', value: address.state?.name },
    { label: 'Postal Code', value: address.postalCode },
    { label: 'Type', value: address.addressType },
  ];

  return (
    <>
      <div className="row" style={{ marginTop: '40px' }}>
        <h5>Addresses</h5>
        <a href="/" onClick={(event) => addAddress(event)}>
          Add Address
        </a>
        <div className="row">
          {tradingPartner?.addresses?.length ? (
            tradingPartner.addresses.map((address) => (
              <DisplayTable
                data={getDisplayData(address)}
                style={{ marginBottom: '40px' }}
              />
            ))
          ) : (
            <>
              <div className="divider" />
              <p className="center">No Addresses to display</p>
            </>
          )}
        </div>
      </div>
      <div className="modal" ref={modal}>
        <div className="modal-content">
          <div className="row">
            <h6 className="center">
              {mode === 'ADD' ? 'Add' : 'Update'} Address
            </h6>
          </div>
          <div className="row">
            <div className="input-field col s12">
              <input
                id="name"
                type="text"
                value={values.name}
                onChange={onChange}
              />
              <label htmlFor="name">Name (as it appears on address)</label>
            </div>
            <div className="row">
              <div className="input-field col s12 l8">
                <input
                  id="addressLineOne"
                  type="text"
                  value={values.addressLineOne}
                  onChange={onChange}
                />
                <label htmlFor="addressLineOne">Address Line One</label>
              </div>
            </div>
            <div className="row">
              <div className="input-field col s12 l8">
                <input
                  id="addressLineTwo"
                  type="text"
                  value={values.addressLineTwo}
                  onChange={onChange}
                />
                <label htmlFor="addressLineTwo">Address Line Two</label>
              </div>
            </div>
            <div className="row">
              <div className="input-field col s8 l6">
                <input
                  id="city"
                  type="text"
                  value={values.city}
                  onChange={onChange}
                />
                <label htmlFor="city">City</label>
              </div>
              <div className="input-field col s4">
                <select id="stateId" value={values.stateId} onChange={onChange}>
                  <option value=""></option>
                  {stateList?.map(({ id, name }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
                </select>
                <label htmlFor="stateId">State</label>
              </div>
            </div>
            <div className="row">
              <div className="input-field col s6 l4">
                <input
                  id="postalCode"
                  type="text"
                  value={values.postalCode}
                  onChange={onChange}
                />
                <label htmlFor="postalCode">Postal Code</label>
              </div>
              <div className="input-field col s6 l4">
                <select
                  id="addressType"
                  value={values.addressType}
                  onChange={onChange}
                >
                  <option value={AddressType.physical}>
                    {AddressType.physical}
                  </option>
                  <option value={AddressType.mailTo}>
                    {AddressType.mailTo}
                  </option>
                  <option value={AddressType.billTo}>
                    {AddressType.billTo}
                  </option>
                  <option value={AddressType.other}>{AddressType.other}</option>
                </select>
                <label htmlFor="addressType">Address Type</label>
              </div>
            </div>
            <div className="row">
              <div className="col s6 l3 offset-l6">
                <a
                  href="/"
                  className="btn-small blue white-text waves-effect waves-light col s12"
                  onClick={mode === 'ADD' ? createAddress : updateAddress}
                >
                  {mode === 'ADD' ? 'Add' : 'Update'} Address
                </a>
              </div>
              <div className="col s6 l3">
                <a
                  href="/"
                  className="btn-small blue white-text waves-effect waves-light col s12"
                  onClick={cancel}
                >
                  Cancel
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditAddresses;
