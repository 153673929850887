import { HydratedUserCustomerLocation } from '../types/compoundModels.ts';
import endpoints from '../endpoints.ts';
import { Toast } from '../types/models.ts';
import { fetch } from './helpers.ts';

const userCustomerLocation = {
  create: {
    one: async (body: {
      userId: number | string;
      customerLocationId: number | string;
      isAdmin: boolean;
    }): Promise<Toast | null> => {
      const url = endpoints.userCustomerLocation.create.one();
      return await fetch<Toast>(url, 'POST', body);
    },
  },
  read: {
    all: {
      by: {
        customerLocationId: async (body: {
          customerLocationId: number | string;
        }): Promise<HydratedUserCustomerLocation[] | null> => {
          const url =
            endpoints.userCustomerLocation.read.all.by.customerLocationId();
          return await fetch<HydratedUserCustomerLocation[]>(url, 'GET', body);
        },
      },
    },
  },
  update: {
    one: {
      toggleAdmin: async (body: {
        userId: number | string;
        customerLocationId: number | string;
      }): Promise<Toast | null> => {
        const url = endpoints.userCustomerLocation.update.one.toggleAdmin();
        return await fetch<Toast>(url, 'PUT', body);
      },
    },
  },
  delete: {
    one: async (body: {
      userId: number | string;
      customerLocationId: number | string;
    }): Promise<Toast | null> => {
      const url = endpoints.userCustomerLocation.delete.one();
      return await fetch<Toast>(url, 'DELETE', body);
    },
  },
};

export default userCustomerLocation;
