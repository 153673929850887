import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {
  FSAccount,
  FSItem,
  FSProduct,
  FSSession,
  FSSubscription,
} from '../../common/types/fastspringModels';
import { useQuery } from '@tanstack/react-query';
import api from '../../common/api/index.ts';
import endpoints from '../../common/endpoints.ts';
import M from 'materialize-css';

import { CiShoppingCart } from 'react-icons/ci';
import { fsProductDetails } from '../../common/fastspringConstants.ts';
import {
  MMCAPSalesItemsAsArray,
  GeneralSalesItemsAsArray,
} from '../../common/enums.ts';
import fastspring from '../../common/api/fastspring.ts';
import { MdDeleteOutline } from 'react-icons/md';
import { FaRegPlusSquare, FaRegMinusSquare } from 'react-icons/fa';

const medtraxFSUrl =
  'https://medtrax.onfastspring.com/popup-defaultB2B/session';

interface IProps {
  accounts: FSAccount[];
  subscriptions: FSSubscription[];
}

interface IProductDetail {
  displayName: string;
  description: string;
  imgPath: string;
  fsProduct: FSProduct;
}

interface ICartItem {
  productDetail: IProductDetail;
  quantity: number;
}

const ModifySubscription = ({ accounts, subscriptions }: IProps) => {
  const cartModal = useRef<HTMLDivElement>(null);
  const addModal = useRef<HTMLDivElement>(null);
  const [cart, setCart] = useState<ICartItem[]>([]);
  const [productQuantity, setProductQuantity] = useState<number>(1);
  const [hoverRow, setHoverRow] = useState<number>(-1);
  const [hydratedProductDetails, setHydratedProductDetails] = useState<{
    [key: string]: IProductDetail;
  }>({});
  const [isMmcap, setIsMmcap] = useState<boolean>(false);
  const [mmcapProducts, setMmcapProducts] = useState<FSProduct[]>([]);
  const [nonMmcapProducts, setNonMmcapProducts] = useState<FSProduct[]>([]);
  const [currentlyViewedProduct, setCurrentlyViewedProduct] =
    useState<IProductDetail>();

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const { data: products } = useQuery({
    queryKey: [endpoints.fastspring.read.all.products()],
    queryFn: api.fastspring.read.all.products,
  });

  const hydrateProductDetails = useCallback(() => {
    const tempHydratedProductDetails: { [key: string]: IProductDetail } = {};

    for (const productKey in fsProductDetails) {
      const found = products?.filter(
        product => product.product === productKey
      )[0];

      if (found)
        tempHydratedProductDetails[productKey] = {
          displayName: fsProductDetails[productKey].displayName,
          description: fsProductDetails[productKey].description,
          imgPath: fsProductDetails[productKey].imgPath,
          fsProduct: {
            product: productKey,
            pricing: {
              US: {
                currency: found?.pricing['US'].currency,
                price: found?.pricing['US'].price,
                display: found?.pricing['US'].display,
              },
            },
          },
        };
    }
    setHydratedProductDetails(tempHydratedProductDetails);
  }, [setHydratedProductDetails, products]);

  useLayoutEffect(() => {
    if (cartModal.current)
      M.Modal.init(cartModal.current, {
        onOpenStart: () => {
          getAddModal()?.close();
        },
      });

    if (addModal.current)
      M.Modal.init(addModal.current, {
        onCloseEnd: () => {
          setProductQuantity(1);
        },
      });

    const { current: currentCart } = cartModal;
    const { current: currentAdd } = addModal;

    return () => {
      if (currentCart) M.Modal.getInstance(currentCart)?.destroy();
      if (currentAdd) M.Modal.getInstance(currentAdd)?.destroy();
    };
  }, []);

  useEffect(() => {
    setIsMmcap(
      subscriptions.some(subscription =>
        subscription.product.toLocaleLowerCase().includes('mmcap')
      )
    );
  }, [subscriptions]);

  useEffect(() => {
    if (products) {
      setMmcapProducts(
        products.filter(({ product }) =>
          MMCAPSalesItemsAsArray.includes(product)
        )
      );
      setNonMmcapProducts(
        products.filter(({ product }) =>
          GeneralSalesItemsAsArray.includes(product)
        )
      );
      hydrateProductDetails();
    }
  }, [products, hydrateProductDetails]);

  const getCartModal = () =>
    cartModal.current ? M.Modal.getInstance(cartModal.current) : null;

  const getAddModal = () =>
    addModal.current ? M.Modal.getInstance(addModal.current) : null;

  const incrementQuantity = () => setProductQuantity(productQuantity + 1);
  const decrementQuantity = () => {
    const newValue = productQuantity - 1;

    if (newValue > 0) setProductQuantity(newValue);
    else setProductQuantity(1);
  };

  const previewProduct = (
    event: MouseEvent<HTMLAnchorElement>,
    productName: string
  ) => {
    event?.preventDefault();
    getAddModal()?.open();

    setCurrentlyViewedProduct(hydratedProductDetails[productName]);
  };

  const checkoutCart = async (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    getCartModal()?.close();

    if (!cart || cart.length === 0) return;

    const accountNumber = accounts[0]?.account;

    const items: FSItem[] = [];
    for (var i of cart) {
      items.push({
        product: i.productDetail.fsProduct.product,
        quantity: i.quantity,
      });
    }

    const fsSession: FSSession | null = await fastspring.create.cart({
      account: accountNumber,
      items,
    });

    if (fsSession?.id !== null) {
      const redirectUrl = `${medtraxFSUrl}/${fsSession?.id}`;
      window.open(redirectUrl, '_blank');
      // clear cart
      setCart([]);
    } else throw new Error('session response is null');
  };

  const goToCart = (event: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    getCartModal()?.open();
  };

  const getCartQuantity = () =>
    cart.reduce((sum, item) => sum + item?.quantity || 0, 0);

  const getCartTotal = (): number => {
    var cartTotal = 0;
    for (var item of cart) {
      if (
        item.productDetail.fsProduct.pricing['US'].price &&
        item.quantity > 0
      ) {
        cartTotal +=
          item.productDetail.fsProduct.pricing['US']?.price * item.quantity;
      }
    }

    return cartTotal;
  };

  const addToCart = (
    event: MouseEvent<HTMLAnchorElement>,
    productName: string,
    quantity: number
  ) => {
    event?.preventDefault();
    getCartModal()?.open();

    const targetIndex = cart.findIndex(
      item => item.productDetail.fsProduct.product === productName
    );
    if (targetIndex !== -1) {
      const clone = [...cart];
      clone[targetIndex].quantity += quantity;
      setCart(clone);
    } else {
      const cartItem: ICartItem = {
        productDetail: hydratedProductDetails[productName],
        quantity,
      };

      const newCart = [...cart, cartItem];
      setCart(newCart);
    }
  };

  const removeCartItem = (event: MouseEvent<SVGElement>, index: number) => {
    event?.preventDefault();
    const newCart = [...cart];
    newCart.splice(index, 1);
    setCart(newCart);
  };

  const handleMouseEnter = (index: number): void => {
    setHoverRow(index);
  };

  const handleMouseLeave = (): void => {
    setHoverRow(-1);
  };

  return (
    <div>
      <div
        style={{
          position: 'fixed',
          bottom: '80px',
          right: '80px',
          zIndex: '1000',
        }}
      >
        <div
          style={{
            position: 'relative',
            backgroundColor: '#e3f2fd',
            borderRadius: '200px',
            padding: '4px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: '3px 3px 2px #c0c0c0',
          }}
        >
          <a href="/" onClick={event => goToCart(event)}>
            <CiShoppingCart
              style={{
                height: '44px',
                width: '50px',
                position: 'relative',
                top: '4px',
                right: '1px',
              }}
            />
          </a>
          {cart.length ? (
            <div
              className="red white-text bold"
              style={{
                position: 'absolute',
                top: '12px',
                right: '8px',
                height: '16px',
                width: '16px',
                borderRadius: '200px',
              }}
            >
              <p
                className="white-text bold"
                style={{
                  position: 'absolute',
                  fontSize: '.75em',
                  top: '-11px',
                  right: '5.5px',
                }}
              >
                {getCartQuantity()}
              </p>
            </div>
          ) : null}
        </div>
      </div>
      {Object.keys(hydratedProductDetails).length ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: '20px',
            justifyContent: 'start',
            alignItems: 'stretch',
          }}
        >
          {(isMmcap ? mmcapProducts : nonMmcapProducts)?.map(({ product }) => {
            const currentProduct = hydratedProductDetails[product];

            if (currentProduct) {
              const { imgPath, description, displayName, fsProduct } =
                currentProduct;

              return (
                <div
                  key={product}
                  className="col s4"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignSelf: 'stretch',
                  }}
                >
                  <div
                    className="card"
                    style={{ display: 'flex', padding: '20px' }}
                  >
                    <div
                      className="card-content"
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '280px',
                        alignSelf: 'stretch',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          alignSelf: 'stretch',
                        }}
                      >
                        <div
                          className="row"
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <img
                            src={imgPath}
                            alt={`${displayName}`}
                            style={{
                              aspectRatio: 2 / 1,
                              height: '75%',
                              width: '75%',
                              objectFit: 'contain',
                            }}
                          />
                        </div>
                        <div className="row">
                          <h4 className="center">{displayName}</h4>
                        </div>
                        <div className="row" style={{ marginBottom: '40px' }}>
                          <p style={{ textAlign: 'center', fontSize: '1.2em' }}>
                            {description}
                          </p>
                        </div>
                        <div
                          style={{
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'start',
                            alignItems: 'center',
                            gap: '8px',
                          }}
                        >
                          <h6>
                            {currencyFormatter.format(
                              fsProduct.pricing['US'].price || 0
                            )}
                          </h6>
                          <div className="row">
                            <a
                              href="/"
                              onClick={event => previewProduct(event, product)}
                              className="btn-small blue white-text waves-effect waves-light col s12"
                            >
                              Preview Product
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
            return null;
          })}
        </div>
      ) : null}
      <div
        ref={cartModal}
        id="cart-modal"
        className="modal"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div className="modal-content">
          <div className="row">
            <h4>Cart</h4>
            <div className="row" style={{ marginBottom: '40px' }}>
              <table className="highlight">
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th style={{ textAlign: 'right' }}>Line Total</th>
                  </tr>
                </thead>
                <tbody>
                  {cart.length > 0 ? (
                    cart?.map((item, index) => (
                      <tr
                        key={index}
                        style={{
                          cursor: 'pointer',
                          position: 'relative',
                        }}
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={() => handleMouseLeave()}
                      >
                        <td style={{ padding: '2px', textAlign: 'left' }}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'start',
                              gap: '8px',
                              alignItems: 'center',
                            }}
                          >
                            {item.productDetail?.displayName}
                            {hoverRow === index ? (
                              <MdDeleteOutline
                                onClick={event => removeCartItem(event, index)}
                                style={{ height: '1.5rem', width: 'auto' }}
                                className="red-text"
                              />
                            ) : (
                              <div style={{ width: '22.5px' }} />
                            )}
                          </div>
                        </td>
                        <td style={{ padding: '2px', textAlign: 'left' }}>
                          {item.quantity}
                        </td>
                        <td style={{ padding: '2px', textAlign: 'left' }}>
                          {currencyFormatter.format(
                            item?.productDetail.fsProduct.pricing['US'].price ||
                              0
                          )}
                        </td>
                        <td style={{ padding: '2px', textAlign: 'right' }}>
                          {currencyFormatter.format(
                            (item.productDetail?.fsProduct.pricing['US']
                              .price || 1) * item.quantity
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td style={{ padding: '2px' }}>Cart is empty</td>
                    </tr>
                  )}
                  <tr>
                    <td style={{ padding: '2px' }}>
                      <b>Total</b>
                    </td>
                    <td
                      colSpan={3}
                      style={{ textAlign: 'right', padding: '2px' }}
                    >
                      <b>{currencyFormatter.format(getCartTotal())}</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="row">
              <div className="col s3">
                {cart.length > 0 ? (
                  <a
                    href="/"
                    onClick={event => checkoutCart(event)}
                    className="btn-small blue white-text waves-effect waves-light col s12"
                  >
                    Checkout
                  </a>
                ) : (
                  <a
                    href="/"
                    className="btn-small blue white-text waves-effect waves-light col s12"
                    onClick={event => {
                      event.preventDefault();
                      getCartModal()?.close();
                    }}
                  >
                    Close
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref={addModal} id="add-modal" className="modal">
        <div className="modal-content">
          <div
            className="grid-container"
            style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
          >
            <h4 style={{}}>{currentlyViewedProduct?.displayName}</h4>
            <div>Description</div>
            <div>{currentlyViewedProduct?.description}</div>
            <div style={{ display: 'flex', gap: '20px' }}>
              <div>Price: </div>
              <div>
                {currencyFormatter.format(
                  currentlyViewedProduct?.fsProduct.pricing['US'].price || 0
                )}
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ width: '250px' }}>
                <a
                  href="/"
                  onClick={event => {
                    if (currentlyViewedProduct)
                      addToCart(
                        event,
                        currentlyViewedProduct.fsProduct.product,
                        productQuantity
                      );
                  }}
                  className="btn-small blue white-text waves-effect waves-light col s12"
                >
                  Add To Cart
                </a>
              </div>
              <div
                className="quantity-selector"
                style={{
                  display: 'flex',
                  marginTop: '4px',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: '4px',
                }}
              >
                <p style={{fontSize: '1rem', position: 'relative', bottom: '4px'}}>Quantity:</p>
                <a
                  href="/"
                  className="btn-decrement"
                  onClick={event => {event.preventDefault(); decrementQuantity()}}
                >
                  <FaRegMinusSquare style={{ fontSize: '1.8rem' }} />
                </a>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '1px' }}>
                  <span
                    className="quantity-display"
                    style={{
                      fontSize: '1rem',
                      textAlign: 'center',
                      height: '1.8rem',
                      position: 'relative', 
                      bottom: '1px'
                    }}
                  >
                    {productQuantity}
                  </span>
                </div>
                <a
                  href="/"
                  className="btn-increment"
                  onClick={event => {event.preventDefault(); incrementQuantity()}}
                >
                  <FaRegPlusSquare style={{ fontSize: '1.8rem' }} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModifySubscription;
