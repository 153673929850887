import React, {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {
  TradingPartner,
  TradingPartnerAccountNumber,
} from '../../../common/models';
import DisplayTable from '../../../components/DisplayTable.tsx';
import api from '../../../common/api/index.ts';

interface props {
  tradingPartner: TradingPartner;
  reload: () => void;
}

const EditAccountNumbers = ({ reload, tradingPartner }: props) => {
  const modal = useRef<HTMLDivElement>(null);

  const getModal = (): M.Modal | undefined =>
    modal.current ? M.Modal.getInstance(modal.current) : undefined;

  const initValues = () => ({
    accountNumber: '',
  });

  const [values, setValues] = useState(initValues());
  const [mode, setMode] = useState<'ADD' | 'EDIT'>('ADD');
  const [selectedAccountNumber, setSelectedAccountNumber] =
    useState<TradingPartnerAccountNumber>();

  useLayoutEffect(() => {
    const onOpenEnd = () => {
      M.updateTextFields();
    };

    const { current } = modal;
    if (current) M.Modal.init(current, { onOpenEnd });

    return () => {
      getModal()?.destroy();
    };
  }, []);

  const deleteAccountNumber = async (
    event: MouseEvent<HTMLAnchorElement>,
    accountNumber: TradingPartnerAccountNumber
  ) => {
    event?.preventDefault();
    if (
      accountNumber.id &&
      window.confirm(
        `Are you sure you want to delete account number: ${accountNumber.accountNumber}?`
      )
    ) {
      await api.tradingPartner.delete.one.tradingPartnerAccountNumber(
        accountNumber.id
      );
      reload();
    }
  };

  const editAccountNumber = (
    event: MouseEvent<HTMLAnchorElement>,
    accountNumber: TradingPartnerAccountNumber
  ) => {
    event?.preventDefault();
    setValues({ accountNumber: accountNumber.accountNumber });
    setMode('EDIT');
    getModal()?.open();
    setSelectedAccountNumber(accountNumber);
  };

  const getActions = (accountNumber: TradingPartnerAccountNumber) => (
    <>
      <a href="/" onClick={(event) => editAccountNumber(event, accountNumber)}>
        Edit
      </a>
      <a
        href="/"
        onClick={(event) => deleteAccountNumber(event, accountNumber)}
      >
        Delete
      </a>
    </>
  );

  const addAccountNumber = (event: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    setMode('ADD');
    getModal()?.open();
    setValues(initValues());
  };

  const onChange = ({
    target: { id, value },
  }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) =>
    setValues((prev) => ({ ...prev, [id]: value }));

  const updateAccountNumber = useCallback(
    async (event: MouseEvent<HTMLAnchorElement>) => {
      event?.preventDefault();
      if (selectedAccountNumber?.id)
        await api.tradingPartner.update.one.tradingPartnerAccountNumber({
          id: selectedAccountNumber.id,
          accountNumber: values.accountNumber,
        });
      reload();
      getModal()?.close();
    },
    [values, selectedAccountNumber, reload]
  );

  const createAccountNumber = useCallback(
    async (event: MouseEvent<HTMLAnchorElement>) => {
      event?.preventDefault();
      await api.tradingPartner.create.tradingPartnerAccountNumber({
        tradingPartnerId: tradingPartner.id,
        ...values,
      });
      reload();
      getModal()?.close();
    },
    [values, tradingPartner, reload]
  );

  const cancel = (event: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    getModal()?.close();
    setValues(initValues());
  };

  const getDisplayData = (accountNumber: TradingPartnerAccountNumber) => ({
    label: 'Account #',
    value: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        {accountNumber.accountNumber}
        <div
          style={{
            display: 'flex',
            width: '120px',
            justifyContent: 'space-between',
          }}
        >
          {getActions(accountNumber)}
        </div>
      </div>
    ),
  });

  return (
    <>
      <div className="row" style={{ marginTop: '40px' }}>
        <h5>Account Numbers</h5>
        <a href="/" onClick={(event) => addAccountNumber(event)}>
          Add Account Number
        </a>
        <div className="row">
          {tradingPartner?.accountNumbers?.length ? (
            <DisplayTable
              data={tradingPartner.accountNumbers.map((accountNumber) =>
                getDisplayData(accountNumber)
              )}
              style={{ marginBottom: '40px' }}
            />
          ) : (
            <>
              <div className="divider" />
              <p className="center">No Accounts to display</p>
            </>
          )}
        </div>
      </div>
      <div className="modal" ref={modal}>
        <div className="modal-content">
          <div className="row">
            <h6 className="center">
              {mode === 'ADD' ? 'Add' : 'Update'} Account Number
            </h6>
          </div>
          <div className="row">
            <div className="input-field col s12 m5">
              <input
                id="accountNumber"
                type="text"
                value={values.accountNumber}
                onChange={onChange}
              />
              <label htmlFor="accountNumber">Account #</label>
            </div>
          </div>
          <div className="row">
            <div className="col s6 l3 offset-l6">
              <a
                href="/"
                className="btn-small blue white-text waves-effect waves-light col s12"
                onClick={
                  mode === 'ADD' ? createAccountNumber : updateAccountNumber
                }
              >
                {mode === 'ADD' ? 'Add' : 'Update'} Account #
              </a>
            </div>
            <div className="col s6 l3">
              <a
                href="/"
                className="btn-small blue white-text waves-effect waves-light col s12"
                onClick={cancel}
              >
                Cancel
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditAccountNumbers;
