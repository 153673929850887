import React, { MouseEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import endpoints from '../../common/endpoints.ts';
import api from '../../common/api/index.ts';
import { FastspringSubscriptionName } from '../../common/enums.ts';

const validSubscriptions = [
  FastspringSubscriptionName.medtraxBasicService,
  FastspringSubscriptionName.medtraxBasicServiceAnnual,
  FastspringSubscriptionName.medtraxEnterpriseService,
  FastspringSubscriptionName.medtraxEnterpriseServiceAnnual,
  FastspringSubscriptionName.medtraxEssentialsService,
  FastspringSubscriptionName.medtraxEssentialsServiceAnnual,
  FastspringSubscriptionName.medtraxMMCAPService,
  FastspringSubscriptionName.medtraxPlusService,
  FastspringSubscriptionName.medtraxPlusServiceAnnual,
];

const ManageLocations = () => {
  const navigate = useNavigate();
  const [totalSubscriptions, setTotalSubscriptions] = useState<number>();
  const [unusedLocationsCount, setUnusedLocationsCount] = useState<number>(0);

  const { data: customerLocations } = useQuery({
    queryKey: [endpoints.customerLocation.read.all.hydrated.by.userId()],
    queryFn: api.customerLocation.read.all.hydrated.by.userId,
    refetchOnMount: true,
  });

  const { data: subscriptions } = useQuery({
    queryKey: [endpoints.fastspring.read.all.subscriptions.by.userId()],
    queryFn: api.fastspring.read.all.subscriptions.by.userId,
    refetchOnMount: true,
  });

  useEffect(() => {
    const subCount = subscriptions
      ?.filter(sub => sub.active)
      .reduce(
        (acc, sub) =>
          validSubscriptions.includes(sub.product as FastspringSubscriptionName)
            ? acc + sub.quantity
            : acc,
        0
      );

    setTotalSubscriptions(subCount);
    setUnusedLocationsCount((subCount || 0) - (customerLocations?.length || 0));
  }, [subscriptions, customerLocations]);

  const goToLocation = (id: number) => {
    navigate(`/locations/${id}`);
  };

  const goToAddLocation = (event: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    navigate('/locations/add');
  };

  return (
    <div>
      <div className="row">
        <h5>Locations</h5>
      </div>
      {unusedLocationsCount && unusedLocationsCount > 0 ? (
        <div className="row">
          <div className="col s12 m4 offset-m8">
            <a
              href="/"
              className="btn-small blue white-text wave-effect waves-light col s12"
              onClick={goToAddLocation}
            >
              Add Location
            </a>
          </div>
        </div>
      ) : null}
      <div className="row">
        <span>Total Subscriptions: {totalSubscriptions || 0}</span>
        <br />
        <span>Unused Subscriptions: {unusedLocationsCount || 0}</span>
      </div>
      <div className="row">
        <table className="highlight">
          <thead>
            <tr>
              <th>Name</th>
              <th>City, State</th>
            </tr>
          </thead>
          <tbody>
            {customerLocations?.map(
              ({ id, name, customerLocationAddresses: [addr] }) => (
                <tr
                  key={id}
                  style={{ cursor: 'pointer' }}
                  onClick={() => goToLocation(id)}
                >
                  <td style={{ padding: '2px' }}>{name}</td>
                  <td style={{ padding: '2px' }}>{`${addr?.city || ''}${
                    addr?.city && addr?.state ? ', ' : ''
                  }${addr?.state.code || ''}`}</td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ManageLocations;
