import React, {
  ChangeEvent,
  Dispatch,
  MouseEvent,
  SetStateAction,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { TradingPartnerAccountNumber } from '../../../common/models';

interface props {
  accountNumbers: Partial<TradingPartnerAccountNumber>[];
  setAccountNumbers: Dispatch<
    SetStateAction<Partial<TradingPartnerAccountNumber>[]>
  >;
}

const AccountNumbers = ({ accountNumbers, setAccountNumbers }: props) => {
  const modal = useRef<HTMLDivElement>(null);

  const initValues = (): Partial<TradingPartnerAccountNumber> => ({
    accountNumber: '',
  });

  const [values, setValues] = useState(initValues());
  const [edit, setEdit] = useState<boolean>(false);
  const [editIndex, setEditIndex] = useState<number | null>(null);

  useLayoutEffect(() => {
    M.Modal.init(document.querySelectorAll('#add-account-modal'));
    const modalElement = modal.current;
    return () => {
      if (modalElement) M.Modal.getInstance(modalElement)?.destroy();
    };
  }, []);

  const openModal = () => {
    if (modal.current) M.Modal.getInstance(modal.current)?.open();
  };

  const closeModal = (event?: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    if (modal.current) M.Modal.getInstance(modal.current)?.close();
    setValues(initValues());
  };

  const addAccountNumber = (event: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    openModal();
  };

  const onChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
    setValues({ accountNumber: value });

  const saveAccountNumber = (event: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    if (edit) {
      if (editIndex === null) {
        return;
      }
      setAccountNumbers(prev => {
        prev.splice(editIndex, 1, values);
        return prev
      })
    } else {
      setAccountNumbers((prev) => [...prev, values]);
    }
    setEdit(false);
    setEditIndex(null);
    closeModal();
  };

  const editAccountNumber = (
    event: MouseEvent<HTMLAnchorElement>,
    index: number
  ) => {
    event?.preventDefault();
    setEdit(true);
    setEditIndex(index);
    setValues(accountNumbers[index]);
    openModal();
  };


  const deleteAccountNumber = (
    event: MouseEvent<HTMLAnchorElement>,
    index: number
  ) => {
    event?.preventDefault();
    accountNumbers.splice(index, 1);
    setAccountNumbers([...accountNumbers]);
  };

  return (
    <>
      <div className="row">
        <h5>Account Numbers</h5>
      </div>
      <div className="row">
        <table className="highlight">
          <thead>
            <tr>
              <th>Account Number</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {accountNumbers?.map((account, index) => (
              <tr key={index}>
                <td style={{ padding: '2px' }}>{account.accountNumber}</td>
                <td style={{ padding: '2px' }}>
                  <a
                    href="/"
                    onClick={(event) => editAccountNumber(event, index)}
                  >
                    Edit
                  </a>
                </td>
                <td style={{ padding: '2px' }}>
                  <a
                    href="/"
                    onClick={(event) => deleteAccountNumber(event, index)}
                  >
                    Delete
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="row">
        <div className="col s12 m3 offset-m9">
          <a href="/" className="right" onClick={addAccountNumber}>
            Add Account Number
          </a>
        </div>
      </div>
      <div ref={modal} id="add-account-modal" className="modal">
        <div className="modal-content">
          <div className="row">
            <h6 className="center">Add Account Number</h6>
          </div>
          <div className="row">
            <div className="input-field col s12 m8 l5">
              <input
                id="accountNumber"
                type="text"
                value={values.accountNumber}
                onChange={onChange}
              />
              <label htmlFor="accountNumber">Account Number</label>
            </div>
          </div>
          <div className="row">
            <div className="col s12 m3 offset-m6">
              <a
                href="/"
                className="btn-small blue white-text waves-effect waves-light col s12"
                onClick={saveAccountNumber}
              >
                Save
              </a>
            </div>
            <div className="col s12 m3">
              <a
                href="/"
                className="btn-small blue white-text waves-effect waves-light col s12"
                onClick={closeModal}
              >
                Cancel
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountNumbers;
