import endpoints from '../endpoints.ts';
import { State } from "../types/models";
import { fetch } from './helpers.ts';

const state = {
  read: {
    all: async (): Promise<State[] | null> => {
      const url = endpoints.state.read.all();
      return await fetch<State[]>(url, 'GET');
    },
  },
};

export default state;
