import {
  Link,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import ChangePassword from './ChangePassword';
import M from 'materialize-css';
import { useLayoutEffect } from 'react';
import OtherSettings from './OtherSettings';

const SettingsPage = props => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    M.Tabs.init(document.querySelectorAll('.tabs'));
    return () =>
      document
        .querySelectorAll('.tabs')
        .forEach(el => M.Tabs.getInstance(el)?.destroy());
  }, [pathname]);

  return (
    <div>
      <div className="row" style={{ marginTop: '40px' }}>
        <div className="col s12">
          <ul id="settings-tabs" className="tabs">
            <li
              id="password"
              className="tab col"
              onClick={() => navigate('/settings/changepassword')}
            >
              <Link href="#password" to="/settings/changepassword">
                Change Password
              </Link>
            </li>
            <li
              id="other"
              className="tab col"
              onClick={() => navigate('/settings/othersettings')}
            >
              <Link href="#other" to="/settings/changepassword">
                Other Settings
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="row">
        <Routes>
          <Route path="/changepassword" element={<ChangePassword />} />
          <Route path="/othersettings" element={<OtherSettings />} />
        </Routes>
      </div>
    </div>
  );
};

export default SettingsPage;
