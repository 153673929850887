import React, { CSSProperties } from 'react';
import { DisplayTableData } from '../common/types/models.ts';

interface props {
  data: DisplayTableData[] | undefined;
  style?: CSSProperties;
  labelTag?: 'em' | 'b';
}

const DisplayTable = ({ data, style, labelTag }: props) => {
  const getLabel = (
    labelTag?: string,
    label?: string | number | JSX.Element
  ): JSX.Element => {
    if (typeof label === 'string') {
      if (labelTag === 'em')
        return (
          <em
            style={{
              borderBottom: '1px solid #ddd',
              marginRight: '2vw',
              padding: '4px 0px',
              width: '12vw',
              minWidth: '12vw',
              textAlign: 'right',
            }}
          >
            {label}
          </em>
        );
      if (!labelTag || labelTag === 'b')
        return (
          <b
            style={{
              borderBottom: '1px solid #ddd',
              marginRight: '2vw',
              padding: '4px 0px',
              width: '12vw',
              minWidth: '12vw',
              textAlign: 'right',
            }}
          >
            {label}
          </b>
        );
    }
    return (
      <div
        style={{
          borderBottom: '1px solid #ddd',
          marginRight: '2vw',
          padding: '4px 0px',
          width: '12vw',
          minWidth: '12vw',
        }}
      >
        {label}
      </div>
    );
  };

  return (
    <div style={style || {}} className="row">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'stretch',
          justifySelf: 'stretch',
        }}
      >
        {data?.map(({ label, value }, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              alignItems: 'center',
            }}
          >
            {label ? getLabel(labelTag, label) : null}
            {typeof value === 'string' ? (
              <span
                style={{
                  borderBottom: '1px solid #ddd',
                  padding: '4px 0px',
                  overflowWrap: 'break-word',
                  width: label ? 'CALC(100% - 14vw)' : '100%',
                  textAlign: 'left',
                  minHeight: '24px',
                }}
              >
                {value ? value : <span>&nbsp;</span>}
              </span>
            ) : (
              <div
                style={{
                  borderBottom: '1px solid #ddd',
                  padding: '4px 0px',
                  overflowWrap: 'break-word',
                  width: label ? 'CALC(100% - 14vw)' : '100%',
                  textAlign: 'left',
                  minHeight: '24px',
                }}
              >
                {value ? value : <span>&nbsp;</span>}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DisplayTable;
