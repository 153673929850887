import React from 'react';
import { Route, Routes } from 'react-router-dom';
import SubscriptionDetail from './SubscriptionDetail.tsx';
import ModifySubscription from './ModifySubscription.tsx';
import { useQuery } from '@tanstack/react-query';
import api from '../../common/api/index.ts';
import endpoints from '../../common/endpoints.ts';

const SubscriptionRouter = () => {
  const { data: accounts } = useQuery({
    queryKey: [endpoints.fastspring.read.one.account.by.userId()],
    queryFn: api.fastspring.read.one.account.by.userId,
  });

  const { data: subscriptions } = useQuery({
    queryKey: [endpoints.fastspring.read.all.subscriptions.by.userId()],
    queryFn: api.fastspring.read.all.subscriptions.by.userId,
  });

  return (
    <Routes>
      <Route
        path="/"
        element={
          <SubscriptionDetail
            accounts={accounts || []}
            subscriptions={subscriptions || []}
          />
        }
      />
      <Route
        path="/modify"
        element={
          <ModifySubscription
            accounts={accounts || []}
            subscriptions={subscriptions || []}
          />
        }
      />
    </Routes>
  );
};

export default SubscriptionRouter;
