import React, {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { DisplayTableData, TradingPartner } from '../../common/models';
import { useParams } from 'react-router-dom';
import api from '../../common/api/index.ts';
import DisplayTable from '../../components/DisplayTable.tsx';
import EditGlobalLocationNumbers from './edit/globalLocationNumbers.tsx';
import EditAddresses from './edit/addresses.tsx';
import EditContacts from './edit/contacts.tsx';
import EditAccountNumbers from './edit/accountNumbers.tsx';
import { TradingPartnerType } from '../../common/enums.ts';

const TradingPartnerDetail = () => {
  const { id } = useParams();
  const modal = useRef<HTMLDivElement>(null);
  const select = useRef<HTMLSelectElement>(null);

  const initValues = () => ({
    name: '',
    type: TradingPartnerType.upstream,
  });

  const [partner, setPartner] = useState<TradingPartner>();
  const [data, setData] = useState<DisplayTableData[]>([]);
  const [values, setValues] = useState(initValues());

  const onChange = ({
    target: { id, value },
  }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) =>
    setValues((prev) => ({ ...prev, [id]: value }));

  const getModal = (): M.Modal | undefined =>
    modal.current ? M.Modal.getInstance(modal.current) : undefined;

  const loadTradingPartner = useCallback(async () => {
    if (id) {
      const idAsInteger = parseInt(id, 10);
      const partner = await api.tradingPartner.read.one.tradingPartner.by.id(
        idAsInteger
      );

      if (partner) {
        setPartner(partner);
        setData([
          { label: 'Name', value: partner.name },
          { label: 'Type', value: partner.type },
        ]);
      }
    }
  }, [id]);

  useEffect(() => {
    loadTradingPartner();
  }, [loadTradingPartner]);

  useLayoutEffect(() => {
    const {current} = select;

    M.Modal.init(document.querySelectorAll('.modal'));
    M.FormSelect.init(document.querySelectorAll('select'));
    return () => {
      getModal()?.destroy();
      if (current) M.FormSelect.getInstance(current)?.destroy();
    };
  }, []);

  const reload = () => {
    setPartner(undefined);
    loadTradingPartner();
  };

  const editGeneralInfo = (event: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();

    if (partner)
      setValues({
        name: partner?.name,
        type: TradingPartnerType[partner.type],
      });
    getModal()?.open();
    setTimeout(() => {
      M.updateTextFields();
      M.FormSelect.init(document.querySelectorAll('select'));
    }, 50);
  };

  const updateGeneralInfo = async (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    if (partner) {
      await api.tradingPartner.update.one.tradingPartner({
        ...values,
        id: partner.id,
      });
      getModal()?.close();
      setValues(initValues());
      reload();
    }
  };

  const cancel = (event: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    getModal()?.close();
    setValues(initValues());
  };

  return (
    <>
      <div className="row">
        {partner ? (
          <>
            <div className="row" style={{ marginTop: '40px' }}>
              <div>
                <h5>General Info</h5>
                <a href="/" onClick={editGeneralInfo}>
                  Edit
                </a>
              </div>
              <DisplayTable data={data} />
            </div>
            <EditGlobalLocationNumbers
              reload={reload}
              tradingPartner={partner}
            />
            <EditAddresses reload={reload} tradingPartner={partner} />
            <EditContacts reload={reload} tradingPartner={partner} />
            <EditAccountNumbers reload={reload} tradingPartner={partner} />
          </>
        ) : (
          <div className="progress blue-text">
            <div className="indeterminate" />
          </div>
        )}
      </div>
      <div ref={modal} className="modal">
        <div className="modal-content">
          <div className="row">
            <h6 className="center">Edit General Info</h6>
          </div>
        </div>
        <div className="row">
          <div className="input-field col s12 m7">
            <input
              id="name"
              type="text"
              value={values.name}
              onChange={onChange}
            />
            <label htmlFor="name">Name</label>
          </div>
          <div className="input-field col s12 m4">
            <select
              ref={select}
              id="type"
              value={values.type}
              onChange={onChange}
            >
              <option value={TradingPartnerType.upstream}>Upstream</option>
              <option value={TradingPartnerType.downstream}>Downstream</option>
              <option value={TradingPartnerType.both}>Both</option>
            </select>
          </div>
          <div className="row">
            <div className="col s6 l3 offset-l6">
              <a
                href="/"
                className="btn-small blue white-text waves-effect waves-light col s12"
                onClick={updateGeneralInfo}
              >
                Update
              </a>
            </div>
            <div className="col s6 l3">
              <a
                href="/"
                className="btn-small blue white-text waves-effect waves-light col s12"
                onClick={cancel}
              >
                Cancel
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TradingPartnerDetail;
