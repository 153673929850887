import Axios from "axios"
import { useEffect, useState, useLayoutEffect } from "react"
import M from 'materialize-css';
import MedInput from './MedInput';
import MedTable from './MedTable';
import { logout } from "../../func";

const AddMedPage = () => {

    const initValues = () => ({
        locationId: 0
    })

    const [values, setValues] = useState(initValues())
    const [locationList, setLocationList] = useState(null);
    const [medList, setMedList] = useState(null);
    const [loadingMedList, setLoadingMedList] = useState(false);

    const getLocationList = () => {
        Axios.get('/api/v1/customerlocation/read/all/by/userid', {
            params: JSON.parse(localStorage.getItem('auth_data')),
        })
            .then(result => {
                setLocationList(result.data);
                setValues(p => ({ ...p, locationId: result.data[0]?.id }))
            })
            .catch(logout)
    }

    const getMedList = () => {
        if (values.locationId && values.locationId !== '0') {
            setLoadingMedList(true);

            Axios.get('/api/v1/med/read/many/by/locationid', {
                params: {
                    ...JSON.parse(localStorage.getItem('auth_data')),
                    locationId: values.locationId
                }
            })
                .then(result => setMedList(result.data))
                .catch(logout)
                .finally(() => setLoadingMedList(false))
        }
    }

    useEffect(getLocationList, [])
    useEffect(getMedList, [values.locationId])

    useLayoutEffect(() => {
        M.FormSelect.init(document.querySelectorAll('select'));
        return () => document.querySelectorAll('select').forEach(el => M.FormSelect.getInstance(el)?.destroy())
    }, [locationList]);


    const onChange = ({ target: { id, value } }) => setValues(p => ({ ...p, [id]: value }))

    return (
        <div>
            <div className="row">
                <div className="input-field col s12 m4">
                    <select id="locationId" value={values.locationId} onChange={onChange}>
                        {locationList?.map(l => <option key={l.id} value={l.id}>{l.name} ({l.globalLocationNumber})</option>)}
                    </select>
                </div>
            </div>
            <div className="row">
                <MedInput locationId={values.locationId} getMedList={getMedList} />
            </div>
            <div className="row">
                <MedTable medList={medList} loadingMedList={loadingMedList} />
            </div>
        </div>
    )
}

export default AddMedPage