import customer from './customer.ts';
import illegitReport from './illegitReport.ts';
import customerLocationGlobalLocationNumber from './customerLocationGlobalLocationNumber.ts';
import state from './state.ts';
import tradingPartner from './tradingPartner.ts';
import user from './user.ts';
import customerLocation from './customerLocation.ts';
import userCustomerLocation from './userCustomerLocation.ts';
import fastspring from './fastspring.ts';

const api = {
  state,
  tradingPartner,
  customer,
  illegitReport,
  customerLocationGlobalLocationNumber,
  user,
  customerLocation,
  userCustomerLocation,
  fastspring
};

export default api;
