import React, { useEffect, useState } from 'react';
import { FSAccount, FSSubscription } from '../../common/types/fastspringModels';
import { Link } from 'react-router-dom';
import { DisplayTableData } from '../../common/types/models';
import DisplayTable from '../../components/DisplayTable.tsx';

interface IProps {
  accounts: FSAccount[];
  subscriptions: FSSubscription[];
}

const SubscriptionDetail = ({ accounts, subscriptions }: IProps) => {
  const [accountData, setAccountData] = useState<DisplayTableData[][]>([]);
  const [subscriptionData, setSubscriptionData] = useState<
    DisplayTableData[][]
  >([]);
  const [displayCanceledSubscriptions, setDisplayCanceledSubscriptions] =
    useState<boolean>(false);

  useEffect(() => {
    const accountData: DisplayTableData[][] = [];

    accounts?.forEach(acc => {
      const {
        account,
        contact: { company, email, first, last, phone },
        address: { addressLine1, addressLine2, city, postalCode, region },
      } = acc;

      const arr = [
        {
          label: <b>General</b>,
        },
        { label: 'Company Name', value: company },
        { label: 'Account ID', value: account },
        {
          label: <b>User</b>,
        },
        { label: 'Name', value: `${first} ${last}` },
        { label: 'Email', value: email },
        { label: 'Phone', value: phone },
        {
          label: <b>Address</b>,
        },
        { label: 'Address Line 1', value: addressLine1 },
        { label: 'Address Line 2', value: addressLine2 },
        { label: 'City', value: city },
        { label: 'State', value: region.substring(3, 5) },
        { label: 'Postal Code', value: postalCode },
      ];

      accountData.push(arr);
    });

    setAccountData(accountData);
  }, [accounts]);

  useEffect(() => {
    const subscriptionData: DisplayTableData[][] = [];
    subscriptions?.forEach((sub, index) => {
      const {
        active,
        autoRenew,
        beginDisplay,
        canceledDateDisplay,
        display,
        id,
        nextChargeDateDisplay,
        nextChargePreTaxDisplay,
        quantity,
      } = sub;

      const arr = [
        {
          label: (
            <b style={{ fontSize: '1.1em' }}>
              <em>{display}</em>
            </b>
          ),
          value: <div style={{ height: '24px' }}></div>,
        },
        { label: 'Quantity', value: quantity },
        { label: 'Is Active ', value: active ? 'Yes' : 'No' },
        { label: 'First Charge', value: beginDisplay },
        { label: 'Next Charge', value: nextChargeDateDisplay },
        { label: 'Charge Amount', value: nextChargePreTaxDisplay },
        { label: 'Auto Renew', value: autoRenew ? 'Yes' : 'No' },
        { label: 'Subscription ID', value: id },
      ];

      if (canceledDateDisplay) {
        if (!displayCanceledSubscriptions) return;
        arr.push({ label: 'Cancellation Date', value: canceledDateDisplay });
      }

      subscriptionData.push(arr);
    });

    setSubscriptionData(subscriptionData);
  }, [subscriptions, displayCanceledSubscriptions]);

  return (
    <div>
      <div className="row">
        <h5>Accounts</h5>
      </div>
      <div className="row">
        {accountData?.map(data => (
          <DisplayTable
            key={data?.[2]?.value as string}
            data={data}
            labelTag="em"
          />
        ))}
      </div>
      <div
        className="row"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            flex: '1',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
          }}
        >
          <h5>Subscriptions</h5>
          <Link to="/subscription/modify">Modify Subscription</Link>
        </div>
        <div style={{ flex: '1', display: 'flex', justifyContent: 'end' }}>
          <div className="switch">
            <label>
              Show Canceled subscriptions
              <input
                type="checkbox"
                checked={displayCanceledSubscriptions}
                onChange={() => setDisplayCanceledSubscriptions(prev => !prev)}
              />
              <span className="lever"></span>
            </label>
          </div>
        </div>
      </div>
      {subscriptionData?.map((data, index) => (
        <div key={index} className="row" style={{ marginBottom: '40px' }}>
          <DisplayTable data={data} labelTag="em" />
        </div>
      ))}
    </div>
  );
};

export default SubscriptionDetail;
