import M from 'materialize-css';
import { useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { LuPackageOpen } from 'react-icons/lu';
import { BsFiletypeXml } from 'react-icons/bs';
import { IoMdSwap } from 'react-icons/io';
import { MdLocationPin } from 'react-icons/md';
import { useQuery } from '@tanstack/react-query';
import api from '../../common/api/index.ts';
import endpoints from '../../common/endpoints.ts';
import { PiBuildings, PiCreditCardLight, PiUsersLight } from 'react-icons/pi';

const NotificationCounter = props => {
  return (
    <div
      style={{
        position: 'absolute',
        backgroundColor: 'red',
        color: 'white',
        height: '15px',
        width: '15px',
        borderRadius: '50%',
        fontSize: '12px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        left: '47px',
        top: '7px',
        zIndex: '999',
      }}
    >
      <span>{props.warnings}</span>
    </div>
  );
};

const Sidenav = props => {
  const { logout } = props;

  const { pathname } = useLocation();
  const navRef = useRef();

  const { data: user } = useQuery({
    queryKey: [endpoints.user.read.one.by.id()],
    queryFn: api.user.read.one.by.id,
    refetchOnMount: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  useEffect(() => {
    const nav = M.Sidenav.init(navRef.current);

    if (nav) {
      nav.open();
      return () => {
        nav.destroy();
      };
    }
  }, []);

  return (
    <div style={{ margin: '0px', height: '0px', padding: '0px' }}>
      <ul ref={navRef} id="side-navigation" className="sidenav sidenav-fixed">
        <li>
          <div className="user-view" style={{ backgroundColor: '#eeeeeeaa' }}>
            <p className="name" style={{ margin: '0px' }}>
              {user?.firstName} {user?.lastName}
              <span className="grey-text text-darken-1">
                {user?.title ? ` - ${user.title}` : ''}
              </span>
            </p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                minHeight: '50px',
              }}
            >
              <a
                className="email"
                href="/"
                onClick={logout}
                style={{ padding: '0px', margin: '0px', display: 'inline' }}
              >
                Sign Out
              </a>
            </div>
          </div>
        </li>
        <li style={{ marginTop: '20px' }}>
          <Link to="/" className={pathname === '/' ? 'red-text' : ''}>
            <i
              className={`material-icons ${pathname === '/' ? 'red-text' : ''}`}
            >
              home
            </i>
            Home
          </Link>
        </li>
        <li>
          <Link
            to="/gln"
            className={pathname.includes('/gln') ? 'red-text' : ''}
          >
            <i
              className={`material-icons ${
                pathname.includes('/gln') ? 'red-text' : ''
              }`}
            >
              <MdLocationPin />
            </i>
            My GLNs
          </Link>
        </li>
        <li>
          <Link
            to="/tradingpartner"
            className={pathname.includes('/tradingpartner') ? 'red-text' : ''}
          >
            <i
              className={`material-icons ${
                pathname.includes('/tradingpartner') ? 'red-text' : ''
              }`}
            >
              <IoMdSwap />
            </i>
            Trading Partners
          </Link>
        </li>
        <li>
          <Link
            to="/orders"
            className={pathname === '/orders' ? 'red-text' : ''}
          >
            <i
              className={`material-icons ${
                pathname === '/orders' ? 'red-text' : ''
              }`}
            >
              <LuPackageOpen />
            </i>
            My Orders
          </Link>
        </li>
        <li>
          <Link
            to="/searchmeds"
            className={pathname === '/searchmeds' ? 'red-text' : ''}
          >
            <i
              className={`material-icons ${
                pathname === '/searchmeds' ? 'red-text' : ''
              }`}
            >
              content_paste
            </i>
            My Pedigrees
          </Link>
        </li>
        {/* <li>
                    <Link to="/inputmeds" className={pathname === '/inputmeds' ? 'red-text' : ''}>
                        <i className={`material-icons ${pathname === '/inputmeds' ? 'red-text' : ''}`}>add_circle_outline</i>
                        Create A Pedigree
                    </Link>
                </li> */}
        <li>
          <Link
            to="/sendapedigree"
            className={pathname === '/sendapedigree' ? 'red-text' : ''}
          >
            <i
              className={`material-icons ${
                pathname === '/sendapedigree' ? 'red-text' : ''
              }`}
            >
              content_paste_go
            </i>
            Send A Pedigree
          </Link>
        </li>
        <li>
          <Link
            to="/orderupload"
            className={pathname === '/orderupload' ? 'red-text' : ''}
          >
            <i
              className={`material-icons ${
                pathname === '/orderupload' ? 'red-text' : ''
              }`}
            >
              upload_file
            </i>
            Upload An Order{' '}
          </Link>
        </li>
        <li>
          <Link
            to="/reportanincident"
            className={pathname === '/reportanincident' ? 'red-text' : ''}
          >
            <i
              className={`material-icons ${
                pathname === '/reportanincident' ? 'red-text' : ''
              }`}
            >
              report_problem
            </i>
            Report An Incident
          </Link>
        </li>
        <li style={{ position: 'relative' }}>
          <Link
            to="/incidenttracker"
            className={pathname === '/incidenttracker' ? 'red-text' : ''}
          >
            <i
              className={`material-icons ${
                pathname === '/incidenttracker' ? 'red-text' : ''
              }`}
            >
              sync_problem
            </i>
            {props.warnings && props.warnings > 0 ? (
              <NotificationCounter warnings={props.warnings} />
            ) : null}
            Incident Tracker
          </Link>
        </li>
        {(process.env.REACT_APP_FEATURE_ENV === 'dev' ||
          process.env.REACT_APP_FEATURE_ENV === 'test') && (
          <li>
            <Link
              to="/demo/epcis/file/generator"
              className={
                pathname === '/demo/epcis/file/generator' ? 'red-text' : ''
              }
            >
              <i className="material-icons">
                <BsFiletypeXml
                  className={
                    pathname === '/demo/epcis/file/generator' ? 'red-text' : ''
                  }
                />
              </i>
              Demo EPCIS File Generator
            </Link>
          </li>
        )}
        <div style={{ marginTop: '40px' }} className="divider" />
        {user && user?.id === user?.customer.adminId && (
          <>
            <li>
              <Link
                to="/subscription"
                className={pathname.includes('/subscription') ? 'red-text' : ''}
              >
                <i className="material-icons">
                  <PiCreditCardLight
                    className={
                      pathname.includes('/subscription') ? 'red-text' : ''
                    }
                  />
                </i>
                Manage Subscription
              </Link>
            </li>
            <li>
              <Link
                to="/locations"
                className={pathname.startsWith('/locations') ? 'red-text' : ''}
              >
                <i className="material-icons">
                  <PiBuildings
                    className={
                      pathname.startsWith('/locations') ? 'red-text' : ''
                    }
                  />
                </i>
                Manage Locations
              </Link>
            </li>
          </>
        )}
        {user && (user?.id === user?.customer.adminId ||
        user.userCustomerLocations?.some(ucl => ucl.isAdmin)) ? (
          <li>
            <Link
              to="/users"
              className={pathname.includes('/users') ? 'red-text' : ''}
            >
              <i className="material-icons">
                <PiUsersLight
                  className={pathname.startsWith('/users') ? 'red-text' : ''}
                />
              </i>
              Manage Users
            </Link>
          </li>
        ) : null}
        <li>
          <Link
            to="/settings/changepassword"
            className={pathname.includes('/settings') ? 'red-text' : ''}
          >
            <i
              className={`material-icons ${
                pathname.includes('/settings') ? 'red-text' : ''
              }`}
            >
              settings
            </i>
            Settings
          </Link>
        </li>{' '}
      </ul>
      <a
        href="/"
        data-target="side-navigation"
        className="sidenav-trigger white-text hide-on-large-only"
      >
        <i className="material-icons">menu</i>
      </a>
    </div>
  );
};

export default Sidenav;
