import endpoints from '../endpoints.ts';
import {
  FSAccount,
  FSItem,
  FSProduct,
  FSSession,
  FSSubscription,
} from '../types/fastspringModels.ts';
import { fetch } from './helpers.ts';

const fastspring = {
  create: {
    cart: async (data: {
      account: string;
      items: FSItem[];
    }): Promise<FSSession | null> => {
      const url = endpoints.fastspring.create.cart();
      return await fetch<FSSession>(url, 'POST', data);
    },
  },
  read: {
    one: {
      account: {
        by: {
          userId: async (): Promise<FSAccount[] | null> => {
            const url = endpoints.fastspring.read.one.account.by.userId();
            return await fetch<FSAccount[]>(url, 'GET');
          },
        },
      },
    },
    all: {
      subscriptions: {
        by: {
          userId: async (): Promise<FSSubscription[] | null> => {
            const url = endpoints.fastspring.read.all.subscriptions.by.userId();
            return await fetch<FSSubscription[]>(url, 'GET');
          },
        },
      },
      products: async (): Promise<FSProduct[] | null> => {
        const url = endpoints.fastspring.read.all.products();
        return await fetch<FSProduct[]>(url, 'GET');
      },
    },
  },
  update: {},
  delete: {},
};

export default fastspring;
