import { useQuery } from '@tanstack/react-query';
import React, { MouseEvent, useEffect, useState } from 'react';
import endpoints from '../../common/endpoints.ts';
import api from '../../common/api/index.ts';
import {
  FastspringUnlimitedUserItems,
  FastspringUserCountItems,
} from '../../common/enums.ts';
import { useNavigate } from 'react-router-dom';
import AddUserModal from './modals/AddUserModal.tsx';

const ManageUsers = () => {
  const navigate = useNavigate();

  const [addModalIsOpen, setAddModalIsOpen] = useState<boolean>(false);
  const [maxUsers, setMaxUsers] = useState<number>(0);

  const { data: user } = useQuery({
    queryKey: [endpoints.user.read.one.by.id()],
    queryFn: api.user.read.one.by.id,
  });

  const { data: subscriptions } = useQuery({
    queryKey: [endpoints.fastspring.read.all.subscriptions.by.userId(), user],
    queryFn: api.fastspring.read.all.subscriptions.by.userId,
  });

  const { data: userCount } = useQuery({
    enabled: Boolean(user?.customerId),
    queryKey: [
      endpoints.customer.read.userCount.by.customerId(),
      user?.customerId,
    ],
    queryFn: api.customer.read.userCount.by.customerId,
  });

  const addUser = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setAddModalIsOpen(true);
  };

  useEffect(() => {
    if (!subscriptions) return;
    if (
      Object.values(FastspringUnlimitedUserItems).some(item =>
        subscriptions.map(sub => sub.product.toLocaleLowerCase()).includes(item)
      )
    )
      return setMaxUsers(Infinity);

    const innerMaxUsers = subscriptions.reduce(
      (acc, sub) =>
        acc +
        (Object.values(FastspringUserCountItems)
          .map(item => item.toString())
          .includes(sub.product.toLocaleLowerCase())
          ? sub.quantity
          : 0),
      0
    );

    return setMaxUsers(innerMaxUsers);
  }, [subscriptions, user, userCount]);

  const goToLocation = (locationId: number) => {
    navigate(`/users/location/${locationId}`);
  };

  return (
    <div>
      <div className="row" />
      {(userCount || 0) < maxUsers && (
        <div className="row">
          <div className="col s12 m4 offset-m8">
            <a
              href="/"
              className="btn-small blue white-text waves-effect waves-light col s12"
              onClick={addUser}
            >
              Invite New User
            </a>
          </div>
        </div>
      )}
      <div className="row">
        <span>Total Users: {userCount || 0}</span>
        <br />
        <span>
          Available Invites:{' '}
          {maxUsers - (userCount || 0) === Infinity
            ? 'Unlimited'
            : maxUsers - (userCount || 0)}
        </span>
      </div>
      <div className="row">
        <table className="highlight">
          <thead>
            <tr>
              <th>Location Name</th>
            </tr>
          </thead>
          <tbody>
            {user?.userCustomerLocations
              ?.filter(({ isAdmin }) => isAdmin)
              .map(loc => (
                <tr
                  key={loc.customerLocation.id}
                  onClick={() => goToLocation(loc.customerLocation.id)}
                  style={{ cursor: 'pointer' }}
                >
                  <td style={{ padding: '2px' }}>
                    {loc.customerLocation.name}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <AddUserModal
        isOpen={addModalIsOpen}
        setIsOpen={setAddModalIsOpen}
        customerId={user?.customerId || 0}
      />
    </div>
  );
};

export default ManageUsers;
