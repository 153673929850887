import React, {
  ChangeEvent,
  MouseEvent,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import M from 'materialize-css';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import endpoints from '../../common/endpoints.ts';
import api from '../../common/api/index.ts';
import { IoMdClose } from 'react-icons/io';
import {
  AddressType,
  CustomerLocationAddressType,
} from '../../common/enums.ts';
import { useNavigate } from 'react-router-dom';

const AddLocation = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const initValues = () => ({
    stateId: 0,
    type: CustomerLocationAddressType.physical,
    addressLineOne: '',
    addressLineTwo: '',
    addressLineThree: '',
    city: '',
    zip: '',
    isDefault: false,
  });

  const [values, setValues] = useState([initValues()]);

  const { data: stateList } = useQuery({
    queryKey: [endpoints.state.read.all()],
    queryFn: api.state.read.all,
    staleTime: Infinity
  });

  const { mutate: createCustomerLocation } = useMutation({
    mutationKey: [endpoints.customerLocation.create.one(), values],
    mutationFn: () => api.customerLocation.create.one(values),
    onSuccess: data => {
      queryClient.invalidateQueries({
        queryKey: [endpoints.customerLocation.read.all.by.userId()],
      });
      queryClient.invalidateQueries({
        queryKey: [endpoints.customerLocation.read.all.hydrated.by.userId()],
      });
      if (data) navigate('/locations');
    },
  });

  useLayoutEffect(() => {
    M.FormSelect.init(document.querySelectorAll('select'));
    M.updateTextFields();

    return () => {
      document.querySelectorAll('select')?.forEach(element => {
        M.FormSelect.getInstance(element)?.destroy();
      });
    };
  }, [stateList, values.length]);

  const onChange = ({
    target: { id, type, value },
  }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const [prop, index] = id.split('-');
    if (!index) return;
    if (type === 'checkbox') values[index][prop] = !values[index][prop];
    else values[index][prop] = value;

    //isDefault is a highlander. There can only be one!
    if (prop === 'isDefault' && values[index][prop]) {
      values.forEach((loc, i) => {
        if (i.toString() !== index) loc.isDefault = false;
      });
    }

    setValues([...values]);
  };

  const addAddress = (event: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    setValues(prev => [...prev, initValues()]);
  };

  const deleteAddress = (index: number) => {
    values.splice(index, 1);
    setValues([...values]);
  };

  const saveLocation = (event: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    createCustomerLocation();
  };

  return (
    <div>
      <div className="row">
        <h5>New Location</h5>
      </div>
      {values?.map((_, index) => (
        <div className="card" key={index}>
          <div className="card-content">
            <div className="row">
              <div className="input-field col s10">
                <input
                  id={`addressLineOne-${index}`}
                  type="text"
                  value={values[index].addressLineOne}
                  onChange={onChange}
                />
                <label htmlFor={`addressLineOne-${index}`}>
                  {`Location Name ${
                    index === 0 ? '(if different from above)' : ''
                  }`}
                </label>
              </div>
              <div className="input-field col s2">
                {index ? (
                  <IoMdClose
                    onClick={() => deleteAddress(index)}
                    style={{ cursor: 'pointer', fontSize: '1.5em' }}
                    className="right red-text bold"
                  />
                ) : null}
              </div>
            </div>
            <div className="row">
              <div className="input-field col s12 m9">
                <input
                  id={`addressLineTwo-${index}`}
                  type="text"
                  value={values[index].addressLineTwo}
                  onChange={onChange}
                />
                <label htmlFor={`addressLineTwo-${index}`}>
                  Address Line Two
                </label>
              </div>
            </div>
            <div className="row">
              <div className="input-field col s12 m9">
                <input
                  id={`addressLineThree-${index}`}
                  type="text"
                  value={values[index].addressLineThree}
                  onChange={onChange}
                />
                <label htmlFor={`addressLineThree-${index}`}>
                  Address Line Three
                </label>
              </div>
            </div>
            <div className="row">
              <div className="input-field col s12 m6">
                <input
                  id={`city-${index}`}
                  type="text"
                  value={values[index].city}
                  onChange={onChange}
                />
                <label htmlFor={`city-${index}`}>City</label>
              </div>
              <div className="input-field col s12 m4 xl3">
                <select
                  id={`stateId-${index}`}
                  value={values[index].stateId}
                  onChange={onChange}
                >
                  {stateList?.map(state => (
                    <option key={state.id} value={state.id}>
                      {state.name} - {state.code}
                    </option>
                  ))}
                </select>
                <label htmlFor={`stateId-${index}`}>State</label>
              </div>
            </div>
            <div className="row">
              <div className="input-field col s6 m4 xl3">
                <input
                  id={`zip-${index}`}
                  type="text"
                  value={values[index].zip}
                  onChange={onChange}
                />
                <label htmlFor={`zip-${index}`}>Postal Code</label>
              </div>
              <div className="input-field col s6 m4 xl3">
                <select
                  id={`type-${index}`}
                  value={values[index].type}
                  onChange={onChange}
                >
                  {Object.values(CustomerLocationAddressType).map(type => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="input-field col s12">
                <p>
                  <label>
                    <input
                      id={`isDefault-${index}`}
                      type="checkbox"
                      className="filled-in"
                      checked={values[index].isDefault}
                      onChange={onChange}
                    />
                    <span>Make Default Address</span>
                  </label>
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}
      <div className="row">
        <div className="col s12 m4">
          <a
            href="/"
            className="btn-small blue white-text waves-effect waves-light col s12"
            onClick={addAddress}
          >
            Add Address
          </a>
        </div>
        <div className="col s12 m4 offset-m4">
          <a
            href="/"
            className="btn-small blue white-text waves-effect waves-light col s12"
            onClick={saveLocation}
          >
            Save Location
          </a>
        </div>
      </div>
    </div>
  );
};

export default AddLocation;
