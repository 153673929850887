export enum TradingPartnerType {
  upstream = 'upstream',
  downstream = 'downstream',
  both = 'both',
}

export enum AddressType {
  physical = 'physical',
  mailTo = 'mail-to',
  billTo = 'bill-to',
  other = 'other',
}

export enum CustomerLocationAddressType{
  physical = 'physical',
  shipping = 'shipping',
  billing = 'billing'
}

export enum ContactType {
  phone = 'phone',
  fax = 'fax',
  email = 'email',
  cell = 'cell',
}

export enum IncidentReportType {
  initial = 'initial',
  followup = 'followup',
  terminate = 'terminate',
}

export enum IncidentClassification {
  counterfeit = 'counterfeit',
  diverted = 'diverted',
  stolen = 'stolen',
  intentionalAdulteration = 'intentional adulteration',
  unfitForDistribution = 'unfit for distribution',
  fraudulentTransaction = 'fraudulentTransaction',
}

export enum IncidentDrugUse {
  humanUse = 'human use',
  other = 'other',
}

export enum IncidentDrugDescription {
  finishedPrescriptionDrug = 'finished prescription drug',
  vaccine = 'vaccine',
  plasmaDerivative = 'plasma derivative',
  allergenic = 'allergenic',
  multiple = 'multiple',
}

export enum IncidentDosageForm {
  tablet = 'tablet',
  capsule = 'capsule',
  sublingual = 'sublingual',
  aerosol = 'aerosol',
  oralLiquid = 'oral liquid',
  injectable = 'injectable',
  topical = 'topical',
  suppository = 'suppository',
  other = 'other',
  multiple = 'multiple',
}

export enum IncidentCompanyCategory {
  manufacturer = 'manufacturer',
  wholesaleDistributor = 'wholesale distributor',
  dispenser = 'dispenser (pharmacy)',
  repackager = 'repackager'
}

export enum GLNDisposition {
  shipTo = 'SHIP TO',
  billTo = 'BILL TO',
  both = 'BOTH'
}

export enum FastspringProductName {
  dscsaSOPCreation = 'dscsa-sop-creation',
  dscsaSOPs = 'dscsa-sops',
  medtraxTrainingSession = 'medtrax-training-session',
  trainingSession1Hour = 'training-session-1-hour',
}

export enum FastspringSubscriptionName {
  additionalUserMMCAP = 'additional-user-mmcap',
  additionalUserMonthly = 'additional-user-monthly',
  medtraxBasicService = 'medtrax-basic-service',
  medtraxBasicServiceAnnual = 'medtrax-basic-service-annual',
  medtraxEnterpriseService = 'medtrax-enterprise-service',
  medtraxEnterpriseServiceAnnual = 'medtrax-enterprise-service-annual',
  medtraxEssentialsService = 'medtrax-essentials-service',
  medtraxEssentialsServiceAnnual = 'medtrax-essentials-service-annual',
  medtraxMMCAPService = 'medtrax-mmcap-service',
  medtraxPlusService = 'medtrax-plus-service',
  medtraxPlusServiceAnnual = 'medtrax-plus-service-annual',
  nracAlerts = 'nrac-alerts',
  nracMonthlySubscription = 'nrac-monthly-subscription',
}

export enum FastspringUnlimitedUserItems {
  medtraxEnterpriseService = FastspringSubscriptionName.medtraxEnterpriseService,
  medtraxEnterpriseServiceAnnual = FastspringSubscriptionName.medtraxEnterpriseServiceAnnual
}

export enum FastspringUserCountItems {
  additionalUserMMCAP = FastspringSubscriptionName.additionalUserMMCAP,
  additionalUserMonthly = FastspringSubscriptionName.additionalUserMonthly,
  medtraxBasicService = FastspringSubscriptionName.medtraxBasicService,
  medtraxBasicServiceAnnual = FastspringSubscriptionName.medtraxBasicServiceAnnual,
  medtraxEssentialsService = FastspringSubscriptionName.medtraxEssentialsService,
  medtraxEssentialsServiceAnnual = FastspringSubscriptionName.medtraxEssentialsServiceAnnual,
  medtraxMMCAPService = FastspringSubscriptionName.medtraxMMCAPService,
  medtraxPlusService = FastspringSubscriptionName.medtraxPlusService,
  medtraxPlusServiceAnnual = FastspringSubscriptionName.medtraxPlusServiceAnnual
}

export enum GeneralSalesItems {
  additionalUserMonthly = FastspringSubscriptionName.additionalUserMonthly,
  dscsaSOPs = FastspringProductName.dscsaSOPs,
  medtraxBasicService = FastspringSubscriptionName.medtraxBasicService,
  medtraxEnterpriseService = FastspringSubscriptionName.medtraxEnterpriseService,
  medtraxTrainingSession = FastspringProductName.medtraxTrainingSession,
  nracMonthlySubscription = FastspringSubscriptionName.nracMonthlySubscription, 
}

export const GeneralSalesItemsAsArray = Object.freeze(
  Object.values(GeneralSalesItems)
) as string[];

export enum MMCAPSalesItems {
  additionalUserMMCAP = FastspringSubscriptionName.additionalUserMMCAP,
  dscsaSOPCreation = FastspringProductName.dscsaSOPCreation,
  medtraxMMCAPService = FastspringSubscriptionName.medtraxMMCAPService,
  nracAlerts = FastspringSubscriptionName.nracAlerts,
  trainingSession1Hour = FastspringProductName.trainingSession1Hour,
}

export const MMCAPSalesItemsAsArray = Object.freeze(
  Object.values(MMCAPSalesItems)
) as string[];