import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import medTraxLogo from '../../assets/medtrax_logo.png';
import Axios from 'axios';
import { toast } from '../../func';

const SecurityCode = (props) => {
  const { setFirstFactor, setLoggedIn } = props;

  const ref = useRef({ totp: '' });
  const [errorMessage, setErrorMessage] = useState('');
  const [totp, setTotp] = useState('');

  const onChange = ({ target: { value } }) => {
    setErrorMessage('');
    setTotp(value.toString().toUpperCase());
  };

  const submit = useCallback((e) => {
    e?.preventDefault();
    const { totp } = ref.current;

    if (totp.length !== 6)
      setErrorMessage(
        'One time passcodes must be exactly 6 characters in length'
      );

    Axios.post('/api/v1/auth/update/one/totp', {
      ...JSON.parse(localStorage.getItem('auth_data')),
      totp,
    })
      .then(() => setLoggedIn(true))
      .catch((err) => {
        console.log(err?.response?.data);
        setErrorMessage(
          'Your code did not match our records. Check that the code is typed exactly as it appeared in the email we sent to you. If you keep getting this message please contact us.'
        );
      });
  }, [setLoggedIn]);

  useLayoutEffect(() => {
    const enterListener = ({ keyCode }) => (keyCode === 13 ? submit() : null);

    document.querySelector('#totp')?.addEventListener('keydown', enterListener);
    return () =>
      document
        .querySelector('#totp')
        .removeEventListener('keydown', enterListener);
  }, [submit]);

  const generateNewSecurityCode = (e) => {
    e?.preventDefault();
    setErrorMessage('');
    setTotp('');

    Axios.post(
      '/api/v1/auth/update/one/resendtotp',
      JSON.parse(localStorage.getItem('auth_data'))
    )
      .then((result) => toast(result.data))
      .catch((err) => {
        console.log(err?.response?.data);
        setErrorMessage(
          'An error occurred while generating your new code. Please try again. If the problem persists contact us.'
        );
      });
  };

  useEffect(() => {
    ref.current.totp = totp;
  }, [totp]);

  return (
    <div>
      <div className="row">
        <img src={medTraxLogo} alt="MedTrax Logo" style={{ maxHeight: '80px', margin: '20px' }} />
      </div>
      <div className="row container">
        <div
          className="col s12 m10 offset-m1 l8 offset-l2 xl6 offset-xl3"
          style={{ marginTop: '15vh' }}
        >
          <div className="card">
            <div className="card-content" style={{ minHeight: '40vh' }}>
              <div className="row" style={{ marginBottom: '60px' }}>
                <h5
                  className="center blue-grey-text text-darken-4"
                  style={{ textDecoration: 'underline', marginBottom: '20px' }}
                >
                  One Time Security Code
                </h5>
                <p className="center">
                  We sent a One Time Authentication Code to your email address.
                  Please input that code below to complete the login process.
                </p>
                <p className="center">
                  If you did not receive a code{' '}
                  <a href="/" onClick={generateNewSecurityCode}>
                    Generate Another Code
                  </a>
                  .
                </p>
              </div>
              <div className="row">
                <div className="row col s10 offset-s1">
                  <div className="input-field col s8 offset-s2">
                    <input
                      className="center"
                      id="totp"
                      type="text"
                      value={totp}
                      onChange={onChange}
                      style={{ fontSize: '50px' }}
                    />
                    <label htmlFor="totp">6-Digit Passcode</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <a
                  href="/"
                  className="btn-small blue white-text waves-effect waves-light col s10 offset-s1"
                  onClick={submit}
                >
                  Submit
                </a>
              </div>
              <div className="row">
                <p className="red-text center">{errorMessage}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <a
              className="right"
              style={{ paddingRight: '20px' }}
              href="/"
              onClick={(e) => {
                e.preventDefault();
                setFirstFactor(false);
              }}
            >
              Return to Username and Password
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecurityCode;
