import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import endpoints from '../../common/endpoints.ts';
import api from '../../common/api/index.ts';
import { useParams } from 'react-router-dom';
import M from 'materialize-css';
import { User } from '../../common/types/models.ts';

interface Values {
  userId: string;
  isAdmin: boolean;
}

const UserManagementLocation = () => {
  const queryClient = useQueryClient();
  const { customerLocationId } = useParams();

  const modal = useRef<HTMLDivElement>(null);
  const select = useRef<HTMLSelectElement>(null);

  const initValues = (): Values => ({
    userId: '',
    isAdmin: false,
  });

  const [values, setValues] = useState<Values>(initValues());

  const onChange = ({
    target: { id, value, type },
  }: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    if (type === 'checkbox')
      setValues((prev) => ({ ...prev, [id]: !prev[id] }));
    else setValues((prev) => ({ ...prev, [id]: value }));
  };

  useLayoutEffect(() => {
    const { current } = modal;
    if (current)
      M.Modal.init(current, {
        onOpenEnd: () =>
          select.current ? M.FormSelect.init(select.current) : null,
        onCloseEnd: () => {
          if (select.current)
            M.FormSelect.getInstance(select.current)?.destroy();
          setValues(initValues());
        },
      });
    return () => {
      if (current) M.Modal.getInstance(current)?.destroy();
    };
  }, []);

  const getModal = () =>
    modal.current ? M.Modal.getInstance(modal.current) : null;

  const { data: customer } = useQuery({
    queryKey: [endpoints.customer.read.one.by.userId()],
    queryFn: api.customer.read.one.by.userId,
  });

  const { mutate: createUserCustomerLocation } = useMutation({
    mutationKey: [
      endpoints.userCustomerLocation.create.one(),
      values,
      customerLocationId,
    ],
    mutationFn: () =>
      api.userCustomerLocation.create.one({
        ...values,
        customerLocationId: customerLocationId || 0,
      }),
    onSuccess: () => {
      getModal()?.close();
      queryClient.invalidateQueries({
        queryKey: [
          endpoints.userCustomerLocation.read.all.by.customerLocationId(),
        ],
      });
    },
  });

  const { mutate: updateAdmin } = useMutation({
    mutationKey: [endpoints.userCustomerLocation.update.one.toggleAdmin()],
    mutationFn: api.userCustomerLocation.update.one.toggleAdmin,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          endpoints.userCustomerLocation.read.all.by.customerLocationId(),
        ],
      });
    },
  });

  const { mutate: deleteUser } = useMutation({
    mutationKey: [endpoints.userCustomerLocation.delete.one()],
    mutationFn: api.userCustomerLocation.delete.one,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          endpoints.userCustomerLocation.read.all.by.customerLocationId(),
        ],
      });
    },
  });

  const { data: customerLocation } = useQuery({
    enabled: Boolean(customerLocationId),
    queryKey: [endpoints.customerLocation.read.one.by.id(), customerLocationId],
    queryFn: () =>
      customerLocationId
        ? api.customerLocation.read.one.by.id(customerLocationId)
        : null,
  });

  const { data: userCustomerLocations } = useQuery({
    enabled: Boolean(customerLocationId),
    queryKey: [
      endpoints.userCustomerLocation.read.all.by.customerLocationId(),
      customerLocationId,
    ],
    queryFn: () =>
      customerLocationId
        ? api.userCustomerLocation.read.all.by.customerLocationId({
            customerLocationId,
          })
        : null,
  });

  const { data: user } = useQuery({
    queryKey: [endpoints.user.read.one.by.id()],
    queryFn: api.user.read.one.by.id,
  });

  const { data: allUsers } = useQuery({
    enabled: Boolean(customer),
    queryKey: [endpoints.user.read.all.by.customerId()],
    queryFn: () => api.user.read.all.by.customerId(),
  });

  const currentIsFullAdmin: boolean = useMemo(() => {
    if (!customer || !user) return false;
    return customer.adminId === user.id;
  }, [user, customer]);

  const showEditOptions = useCallback(
    (isAdmin: boolean | undefined, userId: number) => {
      //The order on these matters. And that's all I have to say about that. -KD
      if (!customerLocationId) return false;
      if (userId === user?.id) return false;
      if (currentIsFullAdmin) return true;
      if (isAdmin) return false;

      const currentIsAdmin = user?.userCustomerLocations.find(
        (loc) => loc.customerLocationId === parseInt(customerLocationId)
      )?.isAdmin;

      if (!currentIsAdmin) return false;
      return true;
    },
    [currentIsFullAdmin]
  );

  const openModal = (event?: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    getModal()?.open();
  };

  const closeModal = (event?: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    getModal()?.close();
  };

  const grantAccess = (event?: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    createUserCustomerLocation();
  };

  const toggleAdmin = (
    event: MouseEvent<HTMLAnchorElement>,
    userId: number | string,
    isAdmin?: boolean
  ) => {
    event?.preventDefault();
    if (!customerLocationId) return;

    if (
      !isAdmin &&
      !currentIsFullAdmin &&
      !window.confirm(
        'Are you sure you want to make this user an admin for this location?' +
          'You will not be able to edit this user once they are an admin.'
      )
    )
      return;

    updateAdmin({
      userId,
      customerLocationId,
    });
  };

  const removeUser = (event: MouseEvent<HTMLAnchorElement>, user: User) => {
    event?.preventDefault();
    if (
      customerLocationId &&
      customerLocation &&
      window.confirm(
        `Are you sure you want to delete ${user.firstName} ${user.lastName} from ${customerLocation.name}?`
      )
    )
      deleteUser({
        userId: user.id,
        customerLocationId,
      });
  };

  return (
    <div style={{ paddingTop: '20px' }}>
      <div className="row">
        <h5 className="center">
          <u>{customerLocation?.name}</u>
        </h5>
      </div>
      <div className="row">
        <a href="/" onClick={openModal} className="right">
          Grant User access to {customerLocation?.name || ''}
        </a>
      </div>
      <div className="row">
        <table className="highlight">
          <thead>
            <tr>
              <th>User</th>
              <th>Is Admin</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {userCustomerLocations?.map(
              ({ user: thisUser, isAdmin, userId, customerLocationId }) => (
                <tr
                  key={`${userId}-${customerLocationId}`}
                  style={{ fontStyle: userId === user?.id ? 'italic' : '' }}
                >
                  <td style={{ padding: '2px' }}>
                    {thisUser.firstName} {thisUser.lastName}{' '}
                    {userId === user?.id ? '(me)' : ''}
                  </td>
                  <td style={{ padding: '2px' }}>{isAdmin ? 'Yes' : 'No'}</td>
                  <td style={{ padding: '2px', width: '280px' }}>
                    {showEditOptions(isAdmin, userId) ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <a
                          href="/"
                          onClick={(event) =>
                            toggleAdmin(event, userId, isAdmin)
                          }
                        >
                          {isAdmin ? 'Revoke Admin' : 'Make Admin'}
                        </a>
                        <div />
                        <a
                          href="/"
                          onClick={(event) => removeUser(event, thisUser)}
                        >
                          Remove User
                        </a>
                      </div>
                    ) : null}
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
      <div ref={modal} className="modal">
        <div className="modal-content">
          <h5 className="center">
            Grant Access to {customerLocation?.name || ''}
          </h5>
          <div className="row">
            <div className="input-field col s12 m8">
              <select
                ref={select}
                id="userId"
                value={values.userId}
                onChange={onChange}
              >
                <option value=""></option>
                {allUsers
                  ?.filter(
                    ({ id }) =>
                      !userCustomerLocations?.find((row) => row.userId === id)
                  )
                  .map(({ id, firstName, lastName }) => (
                    <option key={id} value={id}>
                      {firstName} {lastName}
                    </option>
                  ))}
              </select>
              <label htmlFor="userId">User</label>
            </div>
          </div>
          <div className="row">
            <div className="input-field col s12">
              <p>
                <label>
                  <input
                    id="isAdmin"
                    type="checkbox"
                    className="filled-in"
                    checked={values.isAdmin}
                    onChange={onChange}
                  />
                  <span>Grant Admin Privileges</span>
                </label>
              </p>
              {!currentIsFullAdmin && values.isAdmin ? (
                <small className="orange-text text-darken-2">
                  Note: If you grant admin privileges to this user you will not
                  be able to edit this user later.
                </small>
              ) : null}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col s12 m3 offset-m6">
            <a
              href="/"
              onClick={grantAccess}
              className="btn-small blue white-text waves-effect waves-light col s12"
            >
              Grant Access
            </a>
          </div>
          <div className="col s12 m3">
            <a
              href="/"
              onClick={closeModal}
              className="btn-small blue white-text waves-effect waves-light col s12"
            >
              Cancel
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserManagementLocation;
