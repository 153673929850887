import React, {
  ChangeEvent,
  Dispatch,
  MouseEvent,
  SetStateAction,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import M from 'materialize-css';
import { CustomerLocationAddress } from '../../../common/types/models.ts';
import { CustomerLocationAddressType } from '../../../common/enums.ts';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import api from '../../../common/api/index.ts';
import endpoints from '../../../common/endpoints.ts';

interface IProps {
  isOpen: boolean;
  address?: Partial<CustomerLocationAddress>;
  defaultAddressId?: number;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const EditAddressModal = ({
  isOpen,
  address,
  setIsOpen,
  defaultAddressId,
}: IProps) => {
  const queryClient = useQueryClient();
  const modal = useRef<HTMLDivElement>(null);

  const initValues = () => ({
    id: 0,
    addressLineOne: '',
    addressLineTwo: '',
    addressLineThree: '',
    city: '',
    stateId: 0,
    zip: '',
    type: CustomerLocationAddressType.physical,
    isDefault: false,
  });

  const [values, setValues] = useState(initValues());

  const { data: stateList } = useQuery({
    queryKey: [endpoints.state.read.all()],
    queryFn: api.state.read.all,
    staleTime: Infinity,
  });

  const initSelects = () =>
    M.FormSelect.init(document.querySelectorAll('select'));

  useLayoutEffect(() => {
    const { current } = modal;
    if (current) M.Modal.init(current, { onCloseEnd: () => setIsOpen(false) });
    initSelects();

    return () => {
      if (current) M.Modal.getInstance(current)?.destroy();
      document.querySelectorAll('select').forEach(el => {
        if (el) M.FormSelect.getInstance(el)?.destroy();
      });
    };
  }, []);

  const getModal = () =>
    modal.current ? M.Modal.getInstance(modal.current) : null;

  useLayoutEffect(() => {
    const modal = getModal();
    if (modal) {
      if (isOpen) {
        console.log('HERE: ', address);
        setValues({
          ...initValues(),
          ...address,
          isDefault: address?.id === defaultAddressId,
        });
        modal.open();
      } else {
        setValues(initValues());
        modal.close();
      }
    }
  }, [isOpen]);

  useLayoutEffect(() => {
    M.updateTextFields();
    initSelects();
  }, [values]);

  const onChange = ({
    target: { id, value, type },
  }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) =>
    setValues(prev => ({
      ...prev,
      [id]: type === 'checkbox' ? !prev[id] : value,
    }));

  const cancel = (event?: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    getModal()?.close();
  };

  const { mutate: updateCustomerLocationAddress } = useMutation({
    mutationKey: [endpoints.customerLocation.update.one.address(), values],
    mutationFn: () =>
      api.customerLocation.update.one.address({
        ...values,
        customerLocationAddressId: values.id,
      }),
    onSuccess: data => {
      queryClient.invalidateQueries({
        queryKey: [endpoints.customerLocation.read.one.hydrated.by.id()],
      });
      if (data) cancel();
    },
  });

  const updateAddress = (event: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    updateCustomerLocationAddress();
  };

  return (
    <div ref={modal} className="modal">
      <div className="modal-content">
        <div className="row">
          <h5 className="center">Edit Address</h5>
        </div>
        <div className="row">
          <div className="input-field col s10">
            <input
              id="addressLineOne"
              type="text"
              value={values.addressLineOne}
              onChange={onChange}
            />
            <label htmlFor="addressLineOne">
              Address Line 1 (Location Name)
            </label>
          </div>
        </div>
        <div className="row">
          <div className="input-field col s12 m9">
            <input
              id="addressLineTwo"
              type="text"
              value={values.addressLineTwo}
              onChange={onChange}
            />
            <label htmlFor="addressLineTwo">Address Line Two</label>
          </div>
        </div>
        <div className="row">
          <div className="input-field col s12 m9">
            <input
              id="addressLineThree"
              type="text"
              value={values.addressLineThree}
              onChange={onChange}
            />
            <label htmlFor="addressLineThree">Address Line Three</label>
          </div>
        </div>
        <div className="row">
          <div className="input-field col s12 m6">
            <input
              id="city"
              type="text"
              value={values.city}
              onChange={onChange}
            />
            <label htmlFor="city">City</label>
          </div>
          <div className="input-field col s12 m4 xl3">
            <select id="stateId" value={values.stateId} onChange={onChange}>
              {stateList?.map(state => (
                <option key={state.id} value={state.id}>
                  {state.name} - {state.code}
                </option>
              ))}
            </select>
            <label htmlFor="stateId">State</label>
          </div>
        </div>
        <div className="row">
          <div className="input-field col s6 m4 xl3">
            <input
              id="zip"
              type="text"
              value={values.zip}
              onChange={onChange}
            />
            <label htmlFor="zip">Postal Code</label>
          </div>
          <div className="input-field col s6 m4 xl3">
            <select id="type" value={values.type} onChange={onChange}>
              {Object.values(CustomerLocationAddressType).map(type => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row">
          <div className="input-field col s12">
            <p>
              <label>
                <input
                  id="isDefault"
                  type="checkbox"
                  className="filled-in"
                  checked={values.isDefault}
                  onChange={onChange}
                />
                <span>Make Default Address</span>
              </label>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="input-field col s12 m4 right">
            <a
              href="/"
              className="btn-small blue white-text waves-effect waves-light col s12"
              onClick={updateAddress}
            >
              Update Address
            </a>
          </div>
          <div className="input-field col s12 m4 left">
            <a
              href="/"
              className="btn-small blue white-text waves-effect waves-light col s12"
              onClick={cancel}
            >
              cancel
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditAddressModal;
