import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, {
  ChangeEvent,
  Dispatch,
  MouseEvent,
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { HydratedCustomerLocation } from '../../../common/types/compoundModels.ts';
import endpoints from '../../../common/endpoints.ts';
import api from '../../../common/api/index.ts';

interface IProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  location?: Partial<HydratedCustomerLocation>;
}

const EditNameModal = ({ isOpen, setIsOpen, location }: IProps) => {
  const queryClient = useQueryClient();
  const modal = useRef<HTMLDivElement>(null);

  const initValues = () => ({
    name: '',
  });

  const [values, setValues] = useState(initValues());

  useLayoutEffect(() => {
    const { current } = modal;
    if (current)
      M.Modal.init(current, {
        onOpenStart: () => M.updateTextFields(),
        onCloseEnd: () => setIsOpen(false),
      });

    return () => {
      if (current) M.Modal.getInstance(current)?.destroy();
    };
  }, []);

  const getModal = () =>
    modal.current ? M.Modal.getInstance(modal.current) : null;

  useLayoutEffect(() => {
    const modal = getModal();
    const name = location ? location.name || '' : '';
    if (modal) isOpen ? modal.open() : modal.close();
    setValues({ name });
  }, [isOpen]);

  const cancel = (event?: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    getModal()?.close();
  };

  const onChange = ({ target: { id, value } }: ChangeEvent<HTMLInputElement>) =>
    setValues(prev => ({ ...prev, [id]: value }));

  const { mutate: updateCustomerLocationName } = useMutation({
    mutationKey: [
      endpoints.customerLocation.update.one.name(),
      values,
      location?.id,
    ],
    mutationFn: () =>
      api.customerLocation.update.one.name({
        customerLocationId: location?.id || 0,
        name: values.name.trim(),
      }),
    onSuccess: data => {
      queryClient.invalidateQueries({
        queryKey: [endpoints.customerLocation.read.one.hydrated.by.id()],
      });
      if (data) cancel();
    },
  });

  const updateName = (event: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    updateCustomerLocationName();
  };

  return (
    <div ref={modal} className="modal">
      <div className="modal-content">
        <div className="row">
          <h5 className="center">Edit Location Name</h5>
        </div>
        <div className="row">
          <div className="input-field col s12">
            <input
              id="name"
              type="text"
              value={values.name}
              onChange={onChange}
            />
            <label htmlFor="name">Name</label>
          </div>
        </div>
        <div className="row">
          <div className="input-field col s12 m4 right">
            <a
              href="/"
              className="btn-small blue white-text waves-effect waves-light col s12"
              onClick={updateName}
            >
              Update Name
            </a>
          </div>
          <div className="input-field col s12 m4 left">
            <a
              href="/"
              className="btn-small blue white-text waves-effect waves-light col s12"
              onClick={cancel}
            >
              cancel
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditNameModal;
