import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, {
  ChangeEvent,
  Dispatch,
  MouseEvent,
  SetStateAction,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import endpoints from '../../../common/endpoints.ts';
import api from '../../../common/api/index.ts';

interface IProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  customerId: number;
}

const AddUserModal = ({ isOpen, setIsOpen, customerId }: IProps) => {
  const queryClient = useQueryClient();
  const modal = useRef<HTMLDivElement>(null);

  const initValues = () => ({
    emailAddress: '',
    firstName: '',
    lastName: '',
    jobTitle: '',
  });

  const [values, setValues] = useState(initValues());

  useLayoutEffect(() => {
    const { current } = modal;
    if (current) M.Modal.init(current, { onCloseEnd: () => setIsOpen(false) });

    return () => {
      if (current) M.Modal.getInstance(current)?.destroy();
    };
  }, []);

  const getModal = () =>
    modal.current ? M.Modal.getInstance(modal.current) : null;

  useLayoutEffect(() => {
    const modal = getModal();
    setValues(initValues());
    if (modal) {
      if (isOpen) {
        modal.open();
      } else {
        modal.close();
      }
    }
  }, [isOpen]);

  const onChange = ({ target: { id, value } }: ChangeEvent<HTMLInputElement>) =>
    setValues(prev => ({ ...prev, [id]: value }));

  const cancel = (event?: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    getModal()?.close();
  };

  const { mutate: createUser } = useMutation({
    mutationKey: [endpoints.user.create.one(), values, customerId],
    mutationFn: () => api.user.create.one({ ...values, customerId }),
    onSuccess: data => {
      queryClient.invalidateQueries({
        queryKey: [endpoints.customer.read.userCount.by.customerId()],
      });
      queryClient.invalidateQueries({
        queryKey: [endpoints.fastspring.read.all.subscriptions.by.userId()],
      });
      if (data) cancel();
    },
  });

  const save = (event: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    createUser();
  };

  return (
    <div ref={modal} className="modal">
      <div className="modal-content">
        <div className="row">
          <h5 className="center">Invite New User</h5>
        </div>
        <div className="row">
          <div className="input-field col s12">
            <input
              id="emailAddress"
              type="text"
              value={values.emailAddress}
              onChange={onChange}
            />
            <label htmlFor="emailAddress">Email Address</label>
          </div>
        </div>
        <div className="row">
          <div className="input-field col s12 m6">
            <input
              id="firstName"
              type="text"
              value={values.firstName}
              onChange={onChange}
            />
            <label htmlFor="firstName">First Name</label>
          </div>
          <div className="input-field col s12 m6">
            <input
              id="lastName"
              type="text"
              value={values.lastName}
              onChange={onChange}
            />
            <label htmlFor="lastName">Last Name</label>
          </div>
        </div>
        <div className="row">
          <div className="input-field col s12 m5">
            <input
              id="jobTitle"
              type="text"
              value={values.jobTitle}
              onChange={onChange}
            />
            <label htmlFor="jobTitle">Title</label>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col s12 m4 offset-m4">
          <a
            href="/"
            className="btn-small blue white-text waves-effect waves-light col s12"
            onClick={cancel}
          >
            cancel
          </a>
        </div>
        <div className="col s12 m4">
          <a
            href="/"
            className="btn-small blue white-text waves-effect waves-light col s12"
            onClick={save}
          >
            Invite New User
          </a>
        </div>
      </div>
    </div>
  );
};

export default AddUserModal;
