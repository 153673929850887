import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ManageLocations from './ManageLocations.tsx';
import LocationDetail from './LocationDetail.tsx';
import AddLocation from './AddLocation.tsx';

const ManageLocationsRouter = () => (
  <Routes>
    <Route path="/" element={<ManageLocations />} />
    <Route path="/add" element={<AddLocation />} />
    <Route path="/:id" element={<LocationDetail />} />
  </Routes>
);

export default ManageLocationsRouter;
