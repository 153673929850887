import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './pages/login/Login';
import { useState } from 'react';
import Sidenav from './components/layout/sidenav';
import Banner from './components/layout/banner';
import HomePage from './pages/HomePage/HomePage';
import AddMedPage from './pages/addMed/AddMedPage';
import MedDetailPage from './pages/medDetail/MedDetailPage';
import SearchMed from './pages/medSearch/MedSearch';
import SendPedigree from './pages/sendPedigree/sendPedigree';
import ReportAnIncident from './pages/reportAnIncident/reportAnIncident';
import IncidentTracker from './pages/incidentTracker/incidentTracker';
import SettingsPage from './pages/settings/SettingsPage';
import OrderUpload from './pages/orders/orderUpload';
import OrderRouter from './pages/orders/orderRouter';
import DemoEpcisFileGenerator from './pages/demoEpcisFileGenerator/DemoEpcisFileGenerator';
import TradingPartnerRouter from './pages/tradingPartners/TradingPartnerRouter.tsx';
import GlobalLocationNumbersRouter from './pages/globalLocationNumbers/globalLocationNumbersRouter.tsx';
import api from './common/api/index.ts';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import SubscriptionRouter from './pages/subscription/SubscriptionRouter.tsx';
import { useQuery } from '@tanstack/react-query';
import endpoints from './common/endpoints.ts';
import ManageLocationsRouter from './pages/manageLocations/ManageLocationsRouter.tsx';
import ManageUsersRouter from './pages/manageUsers/ManageUsersRouter.tsx';

const queryClient = new QueryClient();

const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);

  const logout = () => {
    localStorage.clear();
    setLoggedIn(false);
  };

  const { data: warnings } = useQuery({
    enabled: loggedIn,
    queryKey: [endpoints.illegitReport.read.count.by.userId()],
    queryFn: api.illegitReport.read.count.by.userId,
  });

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        {loggedIn ? (
          <>
            <Sidenav warnings={warnings} logout={logout} />
            <Banner />
            <div className="offset-for-sidenav">
              <Routes>
                <Route path="/" element={<HomePage warnings={warnings} />} />
                <Route path="/inputmeds" element={<AddMedPage />} />
                <Route path="/meddetail/:medId" element={<MedDetailPage />} />
                <Route path="/searchmeds" element={<SearchMed />} />
                <Route path="/sendapedigree" element={<SendPedigree />} />
                <Route
                  path="/sendapedigree/:serialNumber"
                  element={<SendPedigree />}
                />
                <Route
                  path="/reportanincident/:incidentId/:type"
                  element={<ReportAnIncident warnings={warnings} />}
                />
                <Route
                  path="/reportanincident"
                  element={<ReportAnIncident warnings={warnings} />}
                />
                <Route
                  path="/incidenttracker"
                  element={<IncidentTracker warnings={warnings} />}
                />
                <Route path="/settings/*" element={<SettingsPage />} />
                <Route path="/orders/*" element={<OrderRouter />} />
                <Route path="/orderupload/*" element={<OrderUpload />} />
                <Route
                  path="/tradingpartner/*"
                  element={<TradingPartnerRouter />}
                />
                <Route path="/gln" element={<GlobalLocationNumbersRouter />} />
                {process.env.REACT_APP_NODE_ENV !== 'production' && (
                  <Route
                    path="/demo/epcis/file/generator"
                    element={<DemoEpcisFileGenerator />}
                  />
                )}
                <Route
                  path="/subscription/*"
                  element={<SubscriptionRouter />}
                />
                <Route
                  path="/locations/*"
                  element={<ManageLocationsRouter />}
                />
                <Route path="/users/*" element={<ManageUsersRouter />} />
              </Routes>
            </div>
          </>
        ) : (
          <Login setLoggedIn={setLoggedIn} />
        )}
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default App;
