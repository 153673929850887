import React, {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {
  ContactDetail,
  TradingPartner,
  TradingPartnerContact,
} from '../../../common/models';
import DisplayTable from '../../../components/DisplayTable.tsx';
import api from '../../../common/api/index.ts';
import { ContactType } from '../../../common/enums.ts';

interface props {
  tradingPartner: TradingPartner;
  reload: () => void;
}

const EditContacts = ({ reload, tradingPartner }: props) => {
  const modal = useRef<HTMLDivElement>(null);

  const getModal = (): M.Modal | undefined =>
    modal.current ? M.Modal.getInstance(modal.current) : undefined;

  const initSelect = () => {
    M.FormSelect.init(document.querySelectorAll('#contactType'));
  };

  const initValues = () => ({
    firstName: '',
    lastName: '',
    title: '',
    contactDetails: [] as any[],
    contactType: ContactType.phone,
    contactDetail: '',
  });

  const [values, setValues] = useState(initValues());
  const [mode, setMode] = useState<'ADD' | 'EDIT'>('ADD');
  const [selectedContact, setSelectedContact] =
    useState<TradingPartnerContact>();

  useLayoutEffect(() => {
    const onOpenEnd = () => {
      M.updateTextFields();
      initSelect();
    };

    const { current } = modal;
    if (current) M.Modal.init(current, { onOpenEnd });

    return () => {
      getModal()?.destroy();
      document.querySelectorAll('#stateId').forEach((el) => {
        if (el) M.FormSelect.getInstance(el)?.destroy();
      });
    };
  }, []);

  const deleteContact = async (
    event: MouseEvent<HTMLAnchorElement>,
    contact: TradingPartnerContact
  ) => {
    event?.preventDefault();
    if (
      contact.id &&
      window.confirm(
        `Are you sure you want to delete this ${contact.firstName} ${
          contact.lastName || ''
        } from ${tradingPartner?.name}?`
      )
    ) {
      await api.tradingPartner.delete.one.tradingPartnerContact(contact.id);
      reload();
    }
  };

  const editContact = (
    event: MouseEvent<HTMLAnchorElement>,
    contact: TradingPartnerContact
  ) => {
    event?.preventDefault();
    setValues({
      firstName: contact.firstName,
      lastName: contact.lastName || '',
      title: contact.title || '',
      contactDetails: contact.contactDetails ? contact.contactDetails : [],
      contactType: ContactType.phone,
      contactDetail: '',
    });
    setMode('EDIT');
    getModal()?.open();
    setSelectedContact(contact);
  };

  const getActions = (contact: TradingPartnerContact) => (
    <>
      <a href="/" onClick={(event) => editContact(event, contact)}>
        Edit
      </a>
      <a href="/" onClick={(event) => deleteContact(event, contact)}>
        Delete
      </a>
    </>
  );

  const addGlobalLocationNumber = (event: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    setMode('ADD');
    getModal()?.open();
    setValues(initValues());
  };

  const onChange = ({
    target: { id, value },
  }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) =>
    setValues((prev) => ({ ...prev, [id]: value }));

  const updateContact = useCallback(
    async (event: MouseEvent<HTMLAnchorElement>) => {
      event?.preventDefault();
      if (selectedContact)
        await api.tradingPartner.update.one.tradingPartnerContact({
          id: selectedContact.id,
          firstName: values.firstName,
          lastName: values.lastName || null,
          title: values.title || null,
        });
      reload();
      getModal()?.close();
    },
    [values, selectedContact, reload]
  );

  const createContact = useCallback(
    async (event: MouseEvent<HTMLAnchorElement>) => {
      event?.preventDefault();
      await api.tradingPartner.create.tradingPartnerContact({
        tradingPartnerId: tradingPartner.id,
        ...values,
      });
      reload();
      getModal()?.close();
    },
    [values, tradingPartner, reload]
  );

  const cancel = (event: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    getModal()?.close();
    setValues(initValues());
  };

  const getDisplayData = (contact: TradingPartnerContact) => [
    {
      label: 'Name',
      value: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          {contact.firstName} {contact.lastName || ''}
          <div
            style={{
              display: 'flex',
              width: '120px',
              justifyContent: 'space-between',
            }}
          >
            {getActions(contact)}
          </div>
        </div>
      ),
    },
    { label: 'Title', value: contact.title },
    {
      label: 'Details',
      value: contact.contactDetails ? (
        <DisplayTable
          data={contact.contactDetails?.map((detail) => ({
            label: detail.contactType,
            value: detail.contactDetail,
          }))}
        />
      ) : (
        ''
      ),
    },
  ];

  const deleteContactDetail = useCallback(
    async (event: MouseEvent<HTMLAnchorElement>, index: number) => {
      event?.preventDefault();

      if (mode === 'ADD') {
        const { contactDetails } = values;
        contactDetails.splice(index, 1);
        setValues((prev) => ({ ...prev, contactDetails }));
      } else {
        const contactDetail = values.contactDetails[index] as ContactDetail;
        if (
          window.confirm(
            `Are you sure you want to delete detail: ${
              contactDetail.contactDetail
            } from ${values.firstName} ${values.lastName || ''}?`
          ) &&
          contactDetail.id
        )
          await api.tradingPartner.delete.one.tradingPartnerContactDetail(
            contactDetail.id
          );
        reload();
      }
    },
    [values, mode, reload]
  );

  const addContactDetail = useCallback(
    async (event: MouseEvent<HTMLAnchorElement>) => {
      event?.preventDefault();

      if (mode === 'ADD') {
        const { contactDetails } = values;
        contactDetails.push({
          contactType: values.contactType,
          contactDetail: values.contactDetail,
        });
        setValues((prev) => ({
          ...prev,
          contactDetails: [...contactDetails],
          contactType: ContactType.phone,
          contactDetail: '',
        }));
      } else {
        if (selectedContact)
          await api.tradingPartner.create.tradingPartnerContactDetail({
            tradingPartnerContactId: selectedContact.id,
            contactType: values.contactType,
            contactDetail: values.contactDetail,
          });
        reload();
      }
    },
    [selectedContact, values, reload, mode]
  );

  return (
    <>
      <div className="row" style={{ marginTop: '40px' }}>
        <h5>Contacts</h5>
        <a href="/" onClick={(event) => addGlobalLocationNumber(event)}>
          Add Contact
        </a>
        <div className="row">
          {tradingPartner?.contacts?.length ? (
            tradingPartner.contacts.map((contact, index) => (
              <DisplayTable
                key={index}
                data={getDisplayData(contact)}
                style={{ marginBottom: '40px' }}
              />
            ))
          ) : (
            <>
              <div className="divider" />
              <p className="center">No Contacts to display</p>
            </>
          )}
        </div>
      </div>
      <div className="modal" ref={modal}>
        <div className="modal-content">
          <div className="row">
            <h6 className="center">
              {mode === 'ADD' ? 'Add' : 'Update'} Contact
            </h6>
          </div>
          <div className="row">
            <div className="input-field col s12 m6">
              <input
                id="firstName"
                type="text"
                onChange={onChange}
                value={values.firstName}
              />
              <label htmlFor="firstName">First Name</label>
            </div>
            <div className="input-field col s12 m6">
              <input
                id="lastName"
                type="text"
                onChange={onChange}
                value={values.lastName}
              />
              <label htmlFor="lastName">Last Name</label>
            </div>
          </div>
          <div className="row">
            <div className="input-field col s12 m4">
              <input
                id="title"
                type="text"
                onChange={onChange}
                value={values.title}
              />
              <label htmlFor="title">Title</label>
            </div>
          </div>
          <div className="row">
            <table className="highlight">
              <thead>
                <tr>
                  <th>Contact Type</th>
                  <th>Detail</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {values.contactDetails?.map((detail, index) => (
                  <tr key={index}>
                    <td style={{ padding: '2px' }}>{detail.contactType}</td>
                    <td style={{ padding: '2px' }}>{detail.contactDetail}</td>
                    <td style={{ padding: '2px' }}>
                      <a
                        href="/"
                        onClick={(event) => deleteContactDetail(event, index)}
                      >
                        Delete
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="row">
            <div className="input-field col s12 m4">
              <select
                id="contactType"
                onChange={onChange}
                value={values.contactType}
              >
                <option value={ContactType.phone}>{ContactType.phone}</option>
                <option value={ContactType.cell}>{ContactType.cell}</option>
                <option value={ContactType.email}>{ContactType.email}</option>
                <option value={ContactType.fax}>{ContactType.fax}</option>
              </select>
              <label htmlFor="contactType">Contact Type</label>
            </div>
            <div className="input-field col s12 m4">
              <input
                id="contactDetail"
                type="text"
                value={values.contactDetail}
                onChange={onChange}
              />
              <label htmlFor="contactDetail">{values.contactType}</label>
            </div>
            <div className="input-field col s12 m4">
              <a href="/" onClick={addContactDetail}>
                Add Contact Detail
              </a>
            </div>
          </div>
          <div className="row">
            <div className="row">
              <div className="col s6 l3 offset-l6">
                <a
                  href="/"
                  className="btn-small blue white-text waves-effect waves-light col s12"
                  onClick={mode === 'ADD' ? createContact : updateContact}
                >
                  {mode === 'ADD' ? 'Add' : 'Update'} Contact
                </a>
              </div>
              <div className="col s6 l3">
                <a
                  href="/"
                  className="btn-small blue white-text waves-effect waves-light col s12"
                  onClick={cancel}
                >
                  Cancel
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditContacts;
